import React, { createContext, useEffect } from "react";
import { AUTH_USER_TOKEN } from "../constants/auth";
import { getCurrentUser } from "../services/user";

export const AppContext = createContext({
  user: null,
  setUser: () => {},
  getUser: () => {},
  isLoading: false,
});

export default function AppContextProvider(props) {
  const { children } = props;

  const [user, setUser] = React.useState({
    id: null,
    name: "",
  });

  const [isLoading, setIsLoading] = React.useState(true);

  const [isAuthenticated, setIsAuthenticated] = React.useState(
    localStorage.getItem(AUTH_USER_TOKEN) !== null
  );

  const getUser = () => {
    setIsLoading(true);
    getCurrentUser()
      .then((res) => {
        if (res && res.data) {
          setUser(res.data);
          setIsAuthenticated(true);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setIsAuthenticated(false);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        isAuthenticated,
        getUser,
        isLoading,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
