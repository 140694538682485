export const NEW_APP_STEP = {
  NAME: {
    name: "Qual é o nome do seu estabelecimento?",
    description: "Informe o nome do seu estabelecimento ou negócio.",
    navigate: "name",
  },
  CATEGORY: {
    name: "Qual a categoria do seu estabelecimento?",
    description:
      "Escolha a categoria que melhor descreve o seu negócio, por exemplo, restaurante, salão de beleza, loja, etc.",
    navigate: "category",
  },
  PLACES: {
    name: "Aonde você trabalha?",
    description:
      "Indique se você trabalha no seu próprio estabelecimento ou atende os clientes em outro local.",
    navigate: "places",
  },
  ADDRESS: {
    name: "Qual é o endereço do seu estabelecimento?",
    description:
      "Forneça o endereço do seu estabelecimento, incluindo rua, número, bairro, cidade e CEP, se aplicável.",
    navigate: "address",
  },
  OPERATING: {
    name: "Quais é o horário de funcionamento?",
    description:
      "Defina os horários de funcionamento do seu estabelecimento, incluindo os dias da semana e os horários de abertura e fechamento.",
    navigate: "operating",
  },
  EMPLOYEES: {
    name: "Quais são os seus funcionários?",
    description:
      "Você pode adicionar informações sobre os funcionários a qualquer momento. Isso ajuda os clientes a conhecer a equipe que está à disposição para atendê-los. Se desejar, preencha os detalhes dos funcionários abaixo.",
    navigate: "employees",
  },
  SERVICES: {
    name: "Quais serviços você atende?",
    description:
      "Especifique os serviços que o seu estabelecimento oferece, por exemplo, cortes de cabelo, refeições, produtos, etc.",
    navigate: "services",
  },
  DOMAIN: {
    name: "Qual será o seu domínio na web?",
    description:
      "Ao escolher um domínio, você estará definindo o endereço de acesso ao seu web aplicativo. Isso será essencial para que seus clientes encontrem você na internet.",
    navigate: "domain",
  },
};

export const NEW_APP_STEPS_START = [
  NEW_APP_STEP.NAME,
  NEW_APP_STEP.CATEGORY,
  NEW_APP_STEP.PLACES,
];

export const NEW_APP_STEPS_END = [
  NEW_APP_STEP.OPERATING,
  NEW_APP_STEP.EMPLOYEES,
  NEW_APP_STEP.SERVICES,
  NEW_APP_STEP.DOMAIN,
];

export const NEW_APP_STEPS = [
  ...NEW_APP_STEPS_START,
  NEW_APP_STEP.ADDRESS,
  ...NEW_APP_STEPS_END,
];
