import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import FormDialog from "../FormDialog/FormDialog";
import { formatPhoneNumber } from "../../../utils/string";

const schema = yup
  .object({
    name: yup.string().required("Campo obrigatório"),
    email: yup.string().email("E-mail inválido").required("Campo obrigatório"),
    phone: yup.string(),
  })
  .required();

const EmployeeDialog = ({
  handleClose,
  selectedEmployee,
  handleChangeEmployees,
}) => {
  const defaultValues = selectedEmployee || {
    name: "",
    email: "",
    phone: "",
  };

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = (data) => {
    handleChangeEmployees({ ...selectedEmployee, ...data });
    handleClose();
  };

  return (
    <FormDialog
      open={true}
      title={`${
        selectedEmployee ? "Editar funcionário" : "Cadastrar funcionário"
      }`}
      handleClose={handleClose}
    >
      <Alert severity="info">
        Comece adicionando informações essenciais para este serviço. Você terá a
        oportunidade de inserir uma descrição detalhada e ajustar configurações
        avançadas mais tarde.
      </Alert>

      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <Grid container spacing={2} marginTop={2}>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              label="Nome"
              variant="outlined"
              fullWidth
              {...register("name")}
              error={!!errors.name}
              helperText={errors.name?.message}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              label="E-mail"
              type="email"
              variant="outlined"
              fullWidth
              {...register("email")}
              error={!!errors.email}
              helperText={errors.email?.message}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              label="Telefone"
              variant="outlined"
              fullWidth
              {...register("phone")}
              error={!!errors.phone}
              helperText={errors.phone?.message}
              onChange={(e) => {
                e.target.value = formatPhoneNumber(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} mt={2}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                height: "56px",
              }}
            >
              {`${selectedEmployee ? "Editar" : "Cadastrar"}`}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </FormDialog>
  );
};

export default EmployeeDialog;
