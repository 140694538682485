import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import { createContext, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ManageAppTemplate from "../../components/templates/ManageAppTemplate";
import { NEW_APP_INITIAL_VALUES } from "../../constants/new-app";
import { NEW_APP_STEPS } from "../../constants/new-app-steps";
import { SERVICE_LOCATION } from "../../constants/service-location";
import { createStore } from "../../services/stores";

export const NewAppContext = createContext();

const NewApp = () => {
  const { state, pathname } = useLocation();

  const navigate = useNavigate();
  const [isLoadingCreation, setIsLoadingCreation] = useState();
  const [isFinish, setIsFinish] = useState(false);
  const [app, setApp] = useState();
  const [error, setError] = useState();

  const currentRoute = pathname.split("/")[2];

  const [values, setValues] = useState(NEW_APP_INITIAL_VALUES);

  const steps = NEW_APP_STEPS;

  let activeStep = steps.findIndex((step) => step.navigate === currentRoute);

  const goToStep = (to) => {
    navigate(`/new-app/${to}`);
  };

  const next = () => {
    const nextStep = steps[activeStep + 1];
    goToStep(nextStep.navigate);
  };

  const previus = () => {
    if (activeStep === 0) {
      navigate(-1);
    } else {
      let previusStep = steps[activeStep - 1];

      if (
        previusStep.navigate === "address" &&
        !values.serviceLocations.includes(SERVICE_LOCATION.IN_STORE)
      ) {
        previusStep = steps[activeStep - 2];
      }

      goToStep(previusStep.navigate);
    }
  };

  const handleValues = (data) => {
    setValues((current) => ({ ...current, ...data }));
    const nextStep = steps[activeStep + 1];

    if (nextStep) {
      next();
    } else {
      createApp({ ...values, ...data });
      navigate(`/new-app/finish`);
    }
  };

  const createApp = (data) => {
    setIsFinish(true);
    setIsLoadingCreation(true);

    createStore(data)
      .then((res) => {
        const resData = res.data;

        console.log("resData", resData);

        if ([400, 500].includes(resData.statusCode)) {
          setError(true);
        } else {
          setApp(resData);
        }
      })
      .catch((err) => {
        setError(true);
      })
      .finally(() => {
        setIsLoadingCreation(false);
      });
  };

  return (
    <NewAppContext.Provider
      value={{
        values,
        handleValues,
        next,
        previus,
        app,
        isLoadingCreation,
        error
      }}
    >
      <ManageAppTemplate>
        <Box
          sx={{
            // display: "flex",
            //   alignItems: "center",
            width: "100%",
            minHeight: "100vh",
          }}
        >
          <Container
            maxWidth="sm"
            sx={{
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <Card
              sx={{
                width: "100%",
                minHeight: {
                  xs: "100vh",
                  md: "auto",
                },
                marginY: {
                  xs: 0,
                  md: 2,
                },
              }}
            >
              <CardContent>
                {!isFinish && (
                  <>
                    <Grid
                      container
                      sx={{
                        height: 40,
                      }}
                    >
                      <Grid xs={3}>
                        {(activeStep > 0 || state?.goBack) && (
                          <IconButton onClick={previus}>
                            <ArrowBackIcon />
                          </IconButton>
                        )}
                      </Grid>
                      <Grid xs={6}>
                        <Box>
                          <LinearProgress
                            variant="determinate"
                            value={(100 / steps.length) * activeStep + 1}
                            size={40}
                            thickness={4}
                            sx={{
                              mt: 2.2,
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid xs={3}></Grid>
                    </Grid>

                    <Box
                      sx={{
                        mt: 5,
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="h5">
                        {steps[activeStep]?.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          mt: 1,
                          fontSize: 14,
                        }}
                      >
                        {steps[activeStep]?.description}
                      </Typography>
                    </Box>
                  </>
                )}

                <Box sx={{ mt: 2 }}>
                  <Outlet />
                </Box>
              </CardContent>
            </Card>
          </Container>
        </Box>
      </ManageAppTemplate>
    </NewAppContext.Provider>
  );
};

export default NewApp;
