import { Outlet } from "react-router-dom";
import AppTemplate from "../../components/templates/AppTemplate";
import ContainerCard from "../../components/atoms/ContainerCard";

const Settings = () => {
  return (
    <AppTemplate>
      <ContainerCard>
        <Outlet />
      </ContainerCard>
    </AppTemplate>
  );
};

export default Settings;
