import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import EmployeeDialog from "../../components/organisms/Dialogs/EmployeeDialog";
import useUser from "../../hooks/queries/useUser";
import { NewAppContext } from "./NewApp";

const employeeDataTitle = (item, isOwner) => {
  if (isOwner) {
    return {
      title: `Eu (${item.name})`,
      subheader: "Proprietário",
    };
  }

  return {
    title: `${item.name}`,
    subheader: "Funcionário",
  };
};

const Employees = () => {
  const { values, handleValues } = useContext(NewAppContext);

  const { user } = useUser();

  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isOpenEdit, setIsOpenEdit] = useState(false);

  const initialEmployees =
    values.employees.length > 0
      ? values.employees
      : [
          {
            user: user?._id,
            name: user?.name,
            email: user?.email,
            phone: user?.phone,
            type: "OWNER",
          },
        ];

  const [employees, setEmployees] = useState(
    initialEmployees.map((item, index) => ({ id: index + 1, ...item }))
  );

  const onSubmit = () => {
    handleValues({
      employees,
    });
  };

  const handleClickCreate = () => {
    setIsOpenEdit(true);
    setSelectedEmployee(null);
  };

  const handleClickEdit = (item) => {
    setSelectedEmployee(item);
  };

  const handleRemove = (id) => {
    setEmployees((current) => current.filter((item) => item.id !== id));
  };

  const handleCloseEdit = () => {
    setSelectedEmployee(null);
    setIsOpenEdit(false);
  };

  const generateId = () => {
    if (employees.length > 0) {
      return employees[employees.length - 1].id + 1;
    } else {
      return 1;
    }
  };

  const handleChangeEmployees = (data) => {
    if (data.id) {
      setEmployees((current) =>
        current.map((item) => {
          if (item.id === data.id) {
            return data;
          }

          return item;
        })
      );
    } else {
      setEmployees((current) => [
        ...current,
        {
          id: generateId(),
          ...data,
        },
      ]);
    }
  };

  return (
    <>
      {employees.map((item) => (
        <Box key={item._id}>
          <Card sx={{ mb: 1, border: 0 }} variant="outlined">
            <CardHeader
              avatar={<Avatar aria-label="recipe">R</Avatar>}
              action={
                <>
                  <Tooltip title="Editar funcionário">
                    <IconButton
                      aria-label="edit"
                      onClick={() => handleClickEdit(item)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Remover funcionário">
                    <IconButton
                      aria-label="remove"
                      onClick={() => handleRemove(item.id)}
                      disabled={item.user === user._id}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </>
              }
              title={employeeDataTitle(item, item.user === user._id).title}
              subheader={
                employeeDataTitle(item, item.user === user._id).subheader
              }
            />
          </Card>
          <Divider />
        </Box>
      ))}

      <Box>
        <Card sx={{ mb: 1, border: 0 }} variant="outlined">
          <CardContent>
            <Button
              type="button"
              variant="outlined"
              fullWidth
              onClick={handleClickCreate}
              startIcon={<PersonAddIcon />}
            >
              ADICIONAR FUNCIONÁRIO
            </Button>
            <Typography variant="body1" fontSize={12} textAlign="center" mt={2}>
              Você poderá adicionar outros funcionários posteriomente
            </Typography>
          </CardContent>
        </Card>
      </Box>

      <Button
        variant="contained"
        fullWidth
        sx={{
          height: "56px",
        }}
        onClick={onSubmit}
      >
        CONTINUAR
      </Button>

      {isOpenEdit && (
        <EmployeeDialog
          handleClose={handleCloseEdit}
          selectedEmployee={selectedEmployee}
          handleChangeEmployees={handleChangeEmployees}
        />
      )}
    </>
  );
};

export default Employees;
