import {
  AppBar,
  Box,
  Button,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../organisms/Breadcrumbs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const PageTitle = ({ title, breadcrumbs, action }) => {
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          mb: 1,
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: "flex",
            paddingTop: 2,
            flex: 1,
          }}
        >
          <Breadcrumbs items={breadcrumbs} />
        </Box>

        <Button
          color="inherit"
          onClick={() => navigate(-1)}
          startIcon={<ArrowBackIcon />}
          sx={{
            textTransform: "none",
          }}
          size="small"
        >
          VOLTAR
        </Button>
      </Box>
      {title && (
        <Box
          sx={{
            mb: 2,
            display: "flex",
          }}
        >
          <Grid container>
            <Grid item xs={12} md={9}>
              <Typography variant="h6" component="div">
                {title}
              </Typography>
            </Grid>
            {action && (
              <Grid
                item
                xs={12}
                md={3}
                sx={{
                  mt: {
                    xs: 2,
                    md: 0,
                  },
                }}
              >
                {action}
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default PageTitle;
