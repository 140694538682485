import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import Breadcrumbs from "../../components/organisms/Breadcrumbs";
import useUserApp from "../../hooks/queries/useUserApp";
import { updateAppAddress } from "../../services/apps";
import { formatZipcode } from "../../utils/string";
import { PageTitle } from "../../components/molecules/PageTitle";
import { STATES } from "../../constants/address";
import { useRef } from "react";

const schema = yup.object().shape({
  zipcode: yup.string().required("O CEP é obrigatório"),
  street: yup.string().required("O endereço é obrigatório"),
  number: yup.string().required("O número é obrigatório"),
  complement: yup.string(),
  neighborhood: yup.string().required("O bairro é obrigatório"),
  city: yup.string().required("A cidade é obrigatória"),
  state: yup.string().required("O estado é obrigatório"),
});

const UpdateAddress = () => {
  let navigate = useNavigate();

  const numberRef = useRef(null);

  const { app: userApp, refetch } = useUserApp();

  const address = userApp?.address || null;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      zipcode: address?.zipcode || "",
      street: address?.street || "",
      number: address?.number || "",
      complement: address?.complement || "",
      neighborhood: address?.neighborhood || "",
      city: address?.city || "",
      state: address?.state || "",
      country: address?.country || "",
    },
  });

  const fetchAddressData = async (zipcode) => {
    if (zipcode.length < 9) return;

    try {
      const response = await fetch(`https://viacep.com.br/ws/${zipcode}/json/`);
      const data = await response.json();

      if (!data.erro) {
        // Preencha os campos do formulário com os dados do CEP
        setValue("street", data.logradouro);
        setValue("neighborhood", data.bairro);
        setValue("city", data.localidade);
        setValue("state", data.uf);
        setValue("country", "Brasil"); // Defina o país como Brasil

        if (numberRef.current) {
          numberRef.current.focus();
        }
      }
    } catch (error) {
      console.error("Erro ao buscar dados do CEP:", error);
    }
  };

  const onSubmit = (data) => {
    setIsSubmitting(true);

    updateAppAddress(userApp._id, {
      ...data,
      zipcode: data.zipcode.replace("-", ""),
    })
      .then(() => {
        toast.success("Atualizado com sucesso!");
        refetch();
        navigate("/settings");
      })
      .catch(() => {
        toast.error("Erro ao atualizar!");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <PageTitle
        title={`Configurações`}
        breadcrumbs={[
          {
            label: "Configurações",
            path: "/settings",
          },
          {
            label: "Endereço",
          },
        ]}
      />

      <Box component="form" onSubmit={handleSubmit(onSubmit)} maxWidth="sm">
        <Grid container spacing={2} marginTop={2}>
          <Grid item xs={12}>
            <TextField
              id="zipcode"
              label="CEP"
              variant="outlined"
              fullWidth
              {...register("zipcode")}
              error={!!errors.zipcode}
              helperText={errors.zipcode?.message}
              onChange={(e) => {
                e.target.value = formatZipcode(e.target.value);
                setValue("zipcode", e.target.value);
                fetchAddressData(e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="street"
              label="Endereço"
              variant="outlined"
              fullWidth
              {...register("street")}
              error={!!errors.street}
              helperText={errors.street?.message}
              InputLabelProps={{
                shrink: !!watch("street"),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={numberRef}
              id="number"
              label="Número"
              variant="outlined"
              fullWidth
              {...register("number")}
              error={!!errors.number}
              helperText={errors.number?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="complement"
              label="Complemento"
              variant="outlined"
              fullWidth
              {...register("complement")}
              error={!!errors.complement}
              helperText={errors.complement?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="neighborhood"
              label="Bairro"
              variant="outlined"
              fullWidth
              {...register("neighborhood")}
              error={!!errors.neighborhood}
              helperText={errors.neighborhood?.message}
              InputLabelProps={{
                shrink: !!watch("neighborhood"),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="city"
              label="Cidade"
              variant="outlined"
              fullWidth
              {...register("city")}
              error={!!errors.city}
              helperText={errors.city?.message}
              InputLabelProps={{
                shrink: !!watch("city"),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="state"
              label="Estado"
              variant="outlined"
              fullWidth
              {...register("state")}
              select
              error={!!errors.state}
              value={watch("state")}
            >
              <MenuItem value="" disabled>
                Selecione o estado
              </MenuItem>
              {STATES.map((state) => (
                <MenuItem key={state.value} value={state.value}>
                  {state.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                height: "56px",
              }}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Salvando..." : <>Salvar</>}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default UpdateAddress;
