import { useQuery } from "react-query";
import { AUTH_DOMAIN_NAME } from "../../constants/auth";
import { getByDomain } from "../../services/apps";

const useApp = () => {
  const appDomain = localStorage.getItem(AUTH_DOMAIN_NAME) || "appzei.com";

  const { data, isLoading, error, refetch } = useQuery("app_" + appDomain, () =>
    appDomain ? getByDomain(appDomain) : null
  );

  const app = data?.data || null;

  const styles = app?.styles || [];

  return { app, appDomain, styles, isLoading, error, refetch };
};

export default useApp;
