import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import Title from "../components/atoms/Title";
import UserItem from "../components/molecules/UserItem";
import AppTemplate from "../components/templates/AppTemplate";
import { getLastUsers } from "../services/user";

const Users = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {

  }, []);

  return (
    <AppTemplate>
      <>
        <Box sx={{ mb: 2 }}>
          <Title>Últimos usuários</Title>
        </Box>
        <Box sx={{ marginBottom: "1rem" }}>
          <Grid container spacing={2}>
            {users.map((user) => (
              <Grid item xs={12} sm={6} md={4} key={user.id}>
                <Box key={user.id} sx={{ mb: 1 }}>
                  <UserItem
                    user={{
                      name: user.name,
                      username: user.username,
                    }}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </>
    </AppTemplate>
  );
};

export default Users;
