import { useContext } from "react";
import * as yup from "yup";
import CustomForm from "../../components/organisms/CustomForm/CustomForm";
import { NewAppContext } from "./NewApp";

const schema = yup
  .object({
    name: yup.string().required("Digite o nome").min(2, "Deve ter mais de 2 caracteres"),
  })
  .required();

const INPUTS = [
  {
    name: "name",
    label: "Nome do estabelecimento",
    placeholder: "Digite o nome do seu estabelecimento",
    required: true
  },
];

const Name = () => {
  const { values, handleValues } = useContext(NewAppContext);

  const defaultValues = {
    name: values?.name || "",
  };

  const onSubmit = (data) => {
    handleValues(data);
  };

  return (
    <>
      <CustomForm
        btnText="CONTINUAR"
        schema={schema}
        inputs={INPUTS}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default Name;
