import axios from "axios";
import { AUTH_USER_TOKEN } from "../constants/auth";

const api = axios.create({
  // baseURL: "https://barber-api.onrender.com/",
  // baseURL: "http://localhost:3000",
  baseURL: "https://barberbr-api.herokuapp.com/",
});

// Add a request interceptor TOKEN
api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem(AUTH_USER_TOKEN);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
