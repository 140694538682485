import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { NewAppContext } from "./NewApp";
import { SERVICE_LOCATION } from "../../constants/service-location";

const places = [
  {
    name: "No meu estabelecimento",
    description: "Eu trabalho no meu estabelecimento.",
    type: SERVICE_LOCATION.IN_STORE,
  },
  {
    name: "Na casa do cliente",
    description: "Meus serviços são realizados na casa do cliente.",
    type: SERVICE_LOCATION.CUSTOMER_HOME,
  },
];

const Places = () => {
  const { values, handleValues } = useContext(NewAppContext);

  const [selectedList, setSelectedList] = useState(
    values.serviceLocations || []
  );

  const onSubmit = () => {
    handleValues({
      serviceLocations: selectedList,
    });
  };

  const findItem = (type) => selectedList.find((item) => item === type);
  const isSeleted = (type) => !!findItem(type);

  const handleSelect = (type) => {
    const founded = selectedList.find((item) => item === type);

    if (founded) {
      setSelectedList((current) => current.filter((item) => item !== type));
    } else {
      setSelectedList((current) => [...current, type]);
    }
  };

  return (
    <>
      <FormGroup>
        {places.map((item) => (
          <Box key={item.name}>
            <Box
              sx={{
                cursor: "pointer",
                py: 2,
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isSeleted(item.type)}
                    onChange={() => handleSelect(item.type)}
                  />
                }
                label={item.name}
              />
              <Typography variant="body1" fontSize={14}>
                {item.description}
              </Typography>
            </Box>
            <Divider />
          </Box>
        ))}
      </FormGroup>

      <Grid item xs={12}>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{
            height: "56px",
          }}
          onClick={onSubmit}
          disabled={selectedList.length === 0}
        >
          CONTINUAR
        </Button>
      </Grid>
    </>
  );
};

export default Places;
