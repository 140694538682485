import apiApplogged from "../config/api-app-logged";
import { STORES_PATH } from "../constants/store";

export const getEmployees = () => {
  return apiApplogged.get(`${STORES_PATH}/employees`);
};

export const getEmployee = (id) => {
  return apiApplogged.get(`${STORES_PATH}/employees/${id}`);
};

export const createEmployee = (data) => {
  return apiApplogged.post(`${STORES_PATH}/employees`, data);
};

export const updateEmployee = (id, data) => {
  return apiApplogged.put(`${STORES_PATH}/employees/${id}`, data);
};

export const deleteEmployee = (id) => {
  return apiApplogged.delete(`${STORES_PATH}/employees/${id}`);
};
