import { Box, Card, CircularProgress, ThemeProvider, createTheme } from "@mui/material";
import useApp from "../../../hooks/queries/useApp";

const appTheme = (style) => {
  if (!style) return createTheme({});

  return createTheme({
    palette: {
      mode: style?.type,

      primary: {
        main: style?.primaryColor,
      },
      secondary: {
        main: style?.secondaryColor,
      },
      background: {
        default: style?.backgroundColor,
      },
      text: {
        primary: style?.textColor,
      },
    },
  });
};

const ThemeTemplate = ({ children }) => {
  const { isLoading, styles } = useApp();

  const style = styles.length > 0 ? styles[0] : null;

  if (isLoading && !style)
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Box>
          <CircularProgress color="primary" size="4rem" />
        </Box>
      </Box>
    );

  return (
    <ThemeProvider theme={appTheme(style)}>
      <Card
        sx={{
          borderRadius: 0,
          backgroundColor: style?.backgroundColor,
          minHeight: "100vh",
        }}
      >
        {children}
      </Card>
    </ThemeProvider>
  );
};

export default ThemeTemplate;
