import { Card } from "@mui/material";

const ContainerCard = ({ children }) => {
  return (
    <Card
      sx={{
        p: 2,
      }}
    >
      {children}
    </Card>
  );
};

export default ContainerCard;
