import { Box, Typography } from "@mui/material";
import BreadcrumbsMui from "@mui/material/Breadcrumbs";
import LinkMui from "@mui/material/Link";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ items }) => {
  return (
    <BreadcrumbsMui
      aria-label="breadcrumb"
      sx={{
        mb: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 12
      }}
    >
      <LinkMui underline="hover" color="inherit" component={Link} to={"/"}>
        Início
      </LinkMui>
      {items.map((item, index) => (
        <Box key={index}>
          {index === items.length - 1 ? (
            <Typography color="text.primary">{item.label}</Typography>
          ) : (
            <LinkMui
              color="inherit"
              underline="hover"
              component={Link}
              to={item.path}
            >
              {item.label}
            </LinkMui>
          )}
        </Box>
      ))}
    </BreadcrumbsMui>
  );
};

export default Breadcrumbs;
