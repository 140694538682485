import api from "../config/api";
import apiApplogged from "../config/api-app-logged";
import { STORES_PATH } from "../constants/store";

export const getStoresByUser = () => {
  return apiApplogged.get("/current-user-apps");
};

export const createStore = (app) => {
  return apiApplogged.post("/apps", app);
};

export const updateThemeColor = (themeColor) => {
  return api.put(`${STORES_PATH}/updateThemeColor`, {
    themeColor,
  });
};
