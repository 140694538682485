function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name) {
  let children = `${name.split(" ")[0][0]}${name.split(" ")[0][1]}`;

  if (name.split(" ").length > 1) {
    children = `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
  }

  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: children.toUpperCase(),
  };
}

export const formatZipcode = (cep) => {
  cep = cep.replace(/\D/g, "");

  if (cep.length > 5) {
    cep = cep.substring(0, 8);
    cep = cep.replace(/(\d{5})(\d{0,3})/, "$1-$2");
  }
  return cep;
};

export const formatPhoneNumber = (phoneNumber) => {
  phoneNumber = phoneNumber.replace(/\D/g, "");

  if (phoneNumber.length === 2) {
    phoneNumber = phoneNumber.substring(0, 2);
    phoneNumber = phoneNumber.replace(/(\d{2})/, "($1)");
    return phoneNumber;
  }

  if (phoneNumber.length === 3) {
    phoneNumber = phoneNumber.substring(0, 3);
    phoneNumber = phoneNumber.replace(/(\d{2})(\d)/, "($1) $2");
    return phoneNumber;
  }

  if (phoneNumber.length === 4) {
    phoneNumber = phoneNumber.substring(0, 4);
    phoneNumber = phoneNumber.replace(/(\d{2})(\d{2})/, "($1) $2");
    return phoneNumber;
  }

  if (phoneNumber.length === 5) {
    phoneNumber = phoneNumber.substring(0, 5);
    phoneNumber = phoneNumber.replace(/(\d{2})(\d{3})/, "($1) $2");
    return phoneNumber;
  }

  if (phoneNumber.length === 6) {
    phoneNumber = phoneNumber.substring(0, 6);
    phoneNumber = phoneNumber.replace(/(\d{2})(\d{4})/, "($1) $2");
    return phoneNumber;
  }

  if (phoneNumber.length === 7) {
    phoneNumber = phoneNumber.substring(0, 7);
    phoneNumber = phoneNumber.replace(/(\d{2})(\d{4})(\d)/, "($1) $2-$3");
    return phoneNumber;
  }

  if (phoneNumber.length === 8) {
    phoneNumber = phoneNumber.substring(0, 8);
    phoneNumber = phoneNumber.replace(/(\d{2})(\d{4})(\d{2})/, "($1) $2-$3");
    return phoneNumber;
  }

  if (phoneNumber.length === 9) {
    phoneNumber = phoneNumber.substring(0, 9);
    phoneNumber = phoneNumber.replace(/(\d{2})(\d{4})(\d{3})/, "($1) $2-$3");
    return phoneNumber;
  }

  if (phoneNumber.length === 10) {
    phoneNumber = phoneNumber.substring(0, 10);
    phoneNumber = phoneNumber.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    return phoneNumber;
  }

  if (phoneNumber.length > 10) {
    phoneNumber = phoneNumber.substring(0, 11);
    phoneNumber = phoneNumber.replace(
      /(\d{2})(\d)(\d{4})(\d{4})/,
      "($1) $2 $3-$4"
    );
  }
  return phoneNumber;
};


export function removeSpecialCharacters(inputString) {
  return inputString.replace(/[^\w\s]/g, '');
}

export function removeAccentsAndSpecialCharacters(inputString) {
  return inputString
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^\w\s]/g, '');
}