import { useQuery } from "react-query";
import { getEmployees } from "../../services/employees";


const useEmployees = () => {
  const { data, isLoading, error, refetch } = useQuery("employees", () =>
    getEmployees()
  );

  const employees = data?.data || [];

  return { employees, isLoading, error, refetch };
};

export default useEmployees;
