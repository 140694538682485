import { Box, Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

const ListTitleAction = ({ title, btnTitle, goTo, btnDisabled }) => {
  let navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <Box
        sx={{
          flex: 1,
        }}
      >
        <Typography variant="h6" component="div">
          {title}
        </Typography>
      </Box>
      <Button
        size="small"
        sx={{
          mb: 2,
        }}
        onClick={() => navigate(goTo)}
        startIcon={<AddIcon />}
        disabled={btnDisabled}
      >
        {btnTitle}
      </Button>
    </Box>
  );
};

export default ListTitleAction;
