import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageTitle } from "../components/molecules/PageTitle";
import ManageAppTemplate from "../components/templates/ManageAppTemplate";
import { getStoresByUser } from "../services/stores";
import useUser from "../hooks/queries/useUser";

const appLink = (domain) => `https://${domain}`;

const UserApps = () => {
  let navigate = useNavigate();

  const [apps, setApps] = useState([]);

  const { user } = useUser();

  const setStoreAndNavigate = (store) => {
    localStorage.setItem("appId", store._id);

    window.location.href = "/home";
  };

  const findStores = () => {
    getStoresByUser().then((res) => {
      if (res?.data?.length > 0) {
        setApps(res.data);
      } else {
        navigate("/new-app/name");
      }
    });
  };

  useEffect(() => {
    findStores();
  }, []);

  return (
    <ManageAppTemplate>
      <Box
        sx={{
          p: 2,
        }}
      >
        <PageTitle
          title={`Meus Aplicativos`}
          action={
            <Button
              variant="contained"
              sx={{
                width: "100%",
              }}
              onClick={() =>
                navigate("/new-app/name", { state: { goBack: true } })
              }
            >
              CRIAR NOVO APLICATIVO
            </Button>
          }
          breadcrumbs={[
            {
              label: "Meus Aplicativos",
            },
          ]}
        />

        <Box sx={{ marginBottom: "1rem", mt: 2 }}>
          <Grid container spacing={2}>
            {apps.map((app) => (
              <Grid item xs={12} md={4} key={app._id}>
                <Card sx={{ width: "100%" }}>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {app.name}
                    </Typography>
                    <Box
                      component="a"
                      href={appLink(app.domain)}
                      target="_blank"
                      rel="noreferrer"
                      sx={{
                        textDecoration: "none",
                        fontSize: 12,
                      }}
                      color="primary"
                    >
                      {appLink(app.domain)}
                    </Box>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      fullWidth
                      variant="outlined"
                      onClick={() => setStoreAndNavigate(app)}
                    >
                      ACESSAR PAINEL
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}

            {apps.length === 0 && (
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    fontSize: 16,
                    textAlign: "center",
                    marginTop: "1rem",
                  }}
                >
                  Você não possui nenhum app cadastrado
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </ManageAppTemplate>
  );
};

export default UserApps;
