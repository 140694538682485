import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ListTitleAction from "../../components/molecules/ListTitleAction";
import { PageTitle } from "../../components/molecules/PageTitle";
import DeleteDialog from "../../components/organisms/DeleteDialog/DeleteDialog";
import useEmployees from "../../hooks/queries/useEmployees";
import { deleteProduct } from "../../services/products";
import { formatPhoneNumber } from "../../utils/string";

const Home = () => {
  let navigate = useNavigate();

  const { employees, refetch } = useEmployees();

  const [deleteItemId, setDeleteItemId] = useState(null);

  const handleEdit = (id) => {
    navigate(`${id}/edit`);
  };

  const handleDelete = (id) => {
    setDeleteItemId(id);
  };

  const handleConfirmDelete = () => {
    deleteProduct(deleteItemId)
      .then(() => {
        refetch();
        toast.success("Funcionário excluído com sucesso!");
        setDeleteItemId(null);
      })
      .catch(() => {
        toast.error("Erro ao excluir funcionário!");
      });
  };

  const handleCancelDelete = () => {
    setDeleteItemId(null);
  };

  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            label: "Funcionários",
          },
        ]}
      />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ListTitleAction
            title="Funcionários"
            btnTitle="NOVO FUNCIONÁRIO"
            goTo="/employees/create"
          />

          <TableContainer
            component={Paper}
            sx={{
              mt: 1,
            }}
          >
            <Table sx={{ width: "100%" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>E-mail</TableCell>
                  <TableCell>Telefone</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {employees.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        minWidth: "80px",
                      }}
                    >
                      {row.phone
                        ? formatPhoneNumber(row.phone)
                        : "Não informado"}{" "}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        color="primary"
                        aria-label="edit"
                        component="span"
                        size="small"
                        onClick={() => handleEdit(row._id)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="primary"
                        aria-label="delete"
                        component="span"
                        size="small"
                        onClick={() => handleDelete(row._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <DeleteDialog
        open={!!deleteItemId}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title="Excluir funcionário"
        description="Tem certeza que deseja excluir este funcionário?"
      />
    </>
  );
};

export default Home;
