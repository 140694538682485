import apiApp from "../config/api-app";
import apiApplogged from "../config/api-app-logged";

export const getByDomain = (domain) => {
  return apiApp.get(`/domain-apps/${domain}`);
};

export const getAppById = (appId) => {
  return apiApplogged.get(`/apps/${appId}`);
};

export const updateAppName = (appId, values) => {
  return apiApplogged.patch(`/apps/${appId}/update-name`, values);
};

export const updateAppDomain = (appId, values) => {
  return apiApplogged.patch(`/apps/${appId}/update-domain`, values);
};

export const updateAppAddress = (appId, address) => {
  return apiApplogged.patch(`/apps/${appId}/update-address`, address);
};

export const updateAppStyles = (appId, styles) => {
  return apiApplogged.patch(`/apps/${appId}/update-styles`, styles);
};

export const updateAppPhones = (appId, styles) => {
  return apiApplogged.patch(`/apps/${appId}/update-phones`, styles);
};
