import { Container, Button } from "@mui/material";
import {
  blue,
  green,
  orange,
  red,
  blueGrey,
  grey,
  indigo,
  purple,
  brown,
} from "@mui/material/colors";
import React, { useEffect } from "react";
import Title from "../components/atoms/Title";
import AppTemplate from "../components/templates/AppTemplate";
import { updateThemeColor } from "../services/stores";

const colors = [
  {
    title: "Verrmelho",
    color: red[700],
  },
  {
    title: "Laranja",
    color: orange[700],
  },
  {
    title: "Verde",
    color: green[700],
  },
  {
    title: "Azul",
    color: blue[700],
  },
  {
    title: "Azul acinzentado",
    color: blueGrey[700],
  },
  {
    title: "Cinza",
    color: grey[700],
  },
  {
    title: "Indico",
    color: indigo[700],
  },
  {
    title: "Roxo",
    color: purple[700],
  },
  {
    title: "Marrom",
    color: brown[700],
  },
];

const Customize = () => {
  const setColor = (color) => {
    localStorage.setItem("theme-color", color);

    updateThemeColor(color).then((res) => {
      window.location.reload();
    });
  };

  return (
    <AppTemplate justify="center">
      <Container
        maxWidth="md"
        sx={{
          padding: {
            md: 3,
          },
        }}
      >
        {colors.map((item) => (
          <Button
            sx={{
              color: item.color,
              margin: 1,
            }}
            onClick={() => setColor(item.color)}
            key={item.title}
            variant="outlined"
          >
            {item.title}
          </Button>
        ))}

        <br />
        <br />

        <label>Selecione uma cor personalizada</label>
        <br />
        <br />

        <input
          type="color"
          onChange={(e) => {
            setColor(e.target.value);
          }}
        />

        <br />
        <br />
        <br />

        <Title>Logo da loja</Title>

        <br />
        <br />
        <br />

        <img
          width={200}
          src="https://i.pinimg.com/originals/9f/ed/4e/9fed4e8f720e02964968348b217fb19f.jpg"
        />
      </Container>
    </AppTemplate>
  );
};

export default Customize;
