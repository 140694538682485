import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, MenuItem, TextField } from "@mui/material";
import { useContext, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { STATES } from "../../constants/address";
import { SERVICE_LOCATION } from "../../constants/service-location";
import { formatZipcode } from "../../utils/string";
import { NewAppContext } from "./NewApp";

const schema = yup.object().shape({
  zipcode: yup.string().required("O CEP é obrigatório"),
  street: yup.string().required("O endereço é obrigatório"),
  number: yup.string().required("O número é obrigatório"),
  complement: yup.string(),
  neighborhood: yup.string().required("O bairro é obrigatório"),
  city: yup.string().required("A cidade é obrigatória"),
  state: yup.string().required("O estado é obrigatório"),
});

const Address = () => {
  const { values, handleValues, next } = useContext(NewAppContext);
  const numberRef = useRef(null);

  const { address } = values;

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      zipcode: address?.zipcode ? formatZipcode(address?.zipcode) : "",
      street: address?.street || "",
      number: address?.number || "",
      complement: address?.complement || "",
      neighborhood: address?.neighborhood || "",
      city: address?.city || "",
      state: address?.state || "",
      country: address?.country || "",
    },
  });

  const onSubmit = (data) => {
    handleValues({
      address: {
        ...data,
        zipcode: data.zipcode.replace("-", ""),
      },
    });
  };

  const fetchAddressData = async (zipcode) => {
    if (zipcode.length < 9) return;

    try {
      const response = await fetch(`https://viacep.com.br/ws/${zipcode}/json/`);
      const data = await response.json();

      if (!data.erro) {
        setValue("street", data.logradouro);
        setValue("neighborhood", data.bairro);
        setValue("city", data.localidade);
        setValue("state", data.uf);
        setValue("country", "Brasil");

        if (numberRef.current) {
          numberRef.current.focus();
        }
      }
    } catch (error) {
      console.error("Erro ao buscar dados do CEP:", error);
    }
  };

  useEffect(() => {
    if (!values.serviceLocations.includes(SERVICE_LOCATION.IN_STORE)) {
      next();
    }
  }, [values]);

  return (
    <>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} maxWidth="sm">
        <Grid container spacing={2} marginTop={2}>
          <Grid item xs={12}>
            <TextField
              id="zipcode"
              label="CEP"
              variant="outlined"
              fullWidth
              {...register("zipcode")}
              error={!!errors.zipcode}
              helperText={errors.zipcode?.message}
              onChange={(e) => {
                e.target.value = formatZipcode(e.target.value);
                setValue("zipcode", e.target.value);
                fetchAddressData(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="street"
              label="Endereço"
              variant="outlined"
              fullWidth
              {...register("street")}
              error={!!errors.street}
              helperText={errors.street?.message}
              InputLabelProps={{
                shrink: !!watch("street"),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={numberRef}
              id="number"
              label="Número"
              variant="outlined"
              fullWidth
              {...register("number")}
              error={!!errors.number}
              helperText={errors.number?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="complement"
              label="Complemento"
              variant="outlined"
              fullWidth
              {...register("complement")}
              error={!!errors.complement}
              helperText={errors.complement?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="neighborhood"
              label="Bairro"
              variant="outlined"
              fullWidth
              {...register("neighborhood")}
              error={!!errors.neighborhood}
              helperText={errors.neighborhood?.message}
              InputLabelProps={{
                shrink: !!watch("neighborhood"),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="city"
              label="Cidade"
              variant="outlined"
              fullWidth
              {...register("city")}
              error={!!errors.city}
              helperText={errors.city?.message}
              InputLabelProps={{
                shrink: !!watch("city"),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="state"
              label="Estado"
              variant="outlined"
              fullWidth
              {...register("state")}
              select
              error={!!errors.state}
              value={watch("state")}
            >
              <MenuItem value="" disabled>
                Selecione o estado
              </MenuItem>
              {STATES.map((state) => (
                <MenuItem key={state.value} value={state.value}>
                  {state.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                height: "56px",
              }}
            >
              CONTINUAR
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Address;
