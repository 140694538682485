export const CATEGORIES = [
  {
    _id: "1",
    name: "Barbearias",
    key: "BARBERSHOPS",
    services: [
      {
        name: "Corte de Cabelo",
        hours: 0,
        minutes: 40,
        price: 30.0,
      },
      {
        name: "Barba",
        hours: 0,
        minutes: 20,
        price: 25.0,
      },
      {
        name: "Penteado",
        hours: 0,
        minutes: 45,
        price: 40.0,
      },
      {
        name: "Coloração de Cabelo",
        hours: 1,
        minutes: 30,
        price: 80.0,
      },
    ],
  },
  {
    _id: "2",
    name: "Esteticistas",
    key: "BEAUTICIANS",
    services: [
      {
        name: "Limpeza de Pele",
        hours: 1,
        minutes: 0,
        price: 60.0,
      },
      {
        name: "Massagem Facial",
        hours: 0,
        minutes: 30,
        price: 35.0,
      },
      {
        name: "Tratamento de Acne",
        hours: 1,
        minutes: 15,
        price: 70.0,
      },
    ],
  },
  {
    _id: "3",
    name: "Make Up",
    key: "MAKE_UP",
    services: [
      {
        name: "Maquiagem para Casamentos",
        hours: 1,
        minutes: 0,
        price: 75.0,
      },
      {
        name: "Maquiagem de Noite",
        hours: 0,
        minutes: 45,
        price: 50.0,
      },
      {
        name: "Maquiagem Artística",
        hours: 1,
        minutes: 30,
        price: 90.0,
      },
    ],
  },
  {
    _id: "4",
    name: "Manicure & Nail Designer",
    key: "MANICURE",
    services: [
      {
        name: "Manicure Básica",
        hours: 0,
        minutes: 30,
        price: 25.0,
      },
      {
        name: "Unhas de Gel",
        hours: 1,
        minutes: 15,
        price: 50.0,
      },
      {
        name: "Nail Art",
        hours: 0,
        minutes: 45,
        price: 40.0,
      },
    ],
  },
  {
    _id: "5",
    name: "Salão de beleza",
    key: "BEAUTY_SALON",
    services: [
      {
        name: "Corte de Cabelo Unissex",
        hours: 0,
        minutes: 40,
        price: 35.0,
      },
      {
        name: "Coloração de Cabelo",
        hours: 1,
        minutes: 30,
        price: 70.0,
      },
      {
        name: "Manicure e Pedicure",
        hours: 1,
        minutes: 0,
        price: 50.0,
      },
    ],
  },
  {
    _id: "6",
    name: "Saúde & Bem estar",
    key: "HEALTH_WELLBEING",
    services: [
      {
        name: "Massagem Relaxante",
        hours: 1,
        minutes: 0,
        price: 60.0,
      },
      {
        name: "Yoga e Meditação",
        hours: 1,
        minutes: 30,
        price: 45.0,
      },
      {
        name: "Aulas de Fitness",
        hours: 1,
        minutes: 0,
        price: 55.0,
      },
    ],
  },
  {
    _id: "7",
    name: "Sobrancelhas & Cilios",
    key: "EYEBROWS_LASHES",
    services: [
      {
        name: "Design de Sobrancelhas",
        hours: 0,
        minutes: 30,
        price: 20.0,
      },
      {
        name: "Extensões de Cílios",
        hours: 1,
        minutes: 0,
        price: 40.0,
      },
      {
        name: "Coloração de Sobrancelhas",
        hours: 0,
        minutes: 45,
        price: 30.0,
      },
    ],
  },
  {
    _id: "8",
    name: "Spa & Massagem",
    key: "SPA_MASSAGE",
    services: [
      {
        name: "Massagem Relaxante",
        hours: 1,
        minutes: 0,
        price: 60.0,
      },
      {
        name: "Tratamento de Spa",
        hours: 2,
        minutes: 0,
        price: 120.0,
      },
      {
        name: "Banho de Ofurô",
        hours: 1,
        minutes: 30,
        price: 70.0,
      },
    ],
  },
  {
    _id: "9",
    name: "Outros Serviços",
    key: "OTHERS",
    services: [
      {
        name: "Consultoria de Estilo",
        hours: 1,
        minutes: 0,
        price: 50.0,
      },
      {
        name: "Serviços de Depilação",
        hours: 0,
        minutes: 45,
        price: 40.0,
      },
      {
        name: "Tratamento Capilar",
        hours: 1,
        minutes: 30,
        price: 55.0,
      },
    ],
  },
];
