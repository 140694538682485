import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import Breadcrumbs from "../../components/organisms/Breadcrumbs";
import useCategories from "../../hooks/useCategories";
import {
  createProduct,
  getProduct,
  updateProduct,
} from "../../services/products";
import { PageTitle } from "../../components/molecules/PageTitle";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const schema = yup
  .object({
    name: yup.string().required("Digite o nome do serviço"),
    description: yup.string(),
    price: yup
      .number()
      .typeError("Digite um preço válido")
      .required("Digite o preço do serviço"),
    categories: yup
      .array()
      .of(yup.string().required("Insira uma categoria"))
      .required("Insira pelo menos uma categoria"),
  })
  .required();

const Edit = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { categories, showCategoryName } = useCategories();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      description: "",
      price: "",
      categories: [],
    },
  });

  const onSubmit = (data) => {
    setIsSubmitting(true);

    if (id) {
      updateProduct(id, data)
        .then((response) => {
          toast.success("Serviço atualizado com sucesso!");
          navigate("/services");
        })
        .catch((error) => {
          toast.error("Erro ao atualizar serviço!");
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else {
      createProduct({
        ...data,
        type: "service",
      })
        .then((response) => {
          toast.success("Serviço criado com sucesso!");
          reset();
          navigate("/services");
        })
        .catch((error) => {
          toast.error("Erro ao criar serviço!");
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getProduct(id)
        .then((response) => {
          const data = response.data;

          reset({
            ...data,
            type: "service",
          });
        })
        .catch((error) => {
          toast.error("Erro ao carregar serviço!");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [id, reset]);

  if (isLoading) {
    return <Typography>Carregando...</Typography>;
  }

  return (
    <>
      <PageTitle
        title={`${id ? "Editar serviço" : "Criar serviço"}`}
        breadcrumbs={[
          {
            label: "Serviços",
            path: "/services",
          },
          {
            label: id ? "Editar" : "Criar",
          },
        ]}
      />

      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <Grid container spacing={2} marginTop={2}>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              label="Nome do serviço"
              variant="outlined"
              fullWidth
              {...register("name")}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              label="Descrição do serviço"
              variant="outlined"
              fullWidth
              rows={3}
              multiline
              {...register("description")}
              error={!!errors.description}
              helperText={errors.description?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined" error={!!errors.category}>
              <InputLabel htmlFor="price">Preço</InputLabel>
              <OutlinedInput
                id="price"
                startAdornment={
                  <InputAdornment position="start">R$</InputAdornment>
                }
                label="Preço"
                {...register("price")}
                error={!!errors.price}
              />
              <FormHelperText>{errors.price?.message}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              variant="outlined"
              error={!!errors.categories}
            >
              <InputLabel id="categories">Categorias</InputLabel>

              <Select
                labelId="categories-label"
                id="categories"
                label="Categorias"
                multiple
                {...register("categories")}
                value={watch("categories")}
                input={<OutlinedInput label="Categorias" />}
                renderValue={(selected) =>
                  selected.map((item) => showCategoryName(item)).join(", ")
                }
                MenuProps={MenuProps}
              >
                {categories.map((category) => (
                  <MenuItem key={category._id} value={category._id}>
                    <Checkbox
                      checked={watch("categories").indexOf(category._id) > -1}
                    />
                    <ListItemText primary={category.name} />
                  </MenuItem>
                ))}
              </Select>

              {/* <Select
                labelId="categories"
                label="Categorias"
                // {...register("categories")}
                error={!!errors.categories}
                helperText={errors.categories?.message}
                multiple
              >
                {categories.map((category) => (
                  <MenuItem key={category._id} value={category._id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select> */}
              <Box>
                <Typography variant="caption">
                  Não encontrou a categoria?{" "}
                  <Link to="/services/category/create">
                    Crie uma nova categoria
                  </Link>
                </Typography>
              </Box>
            </FormControl>
          </Grid>
          {!id && (
            <Grid item xs={6}>
              <Button
                variant="outlined"
                fullWidth
                sx={{
                  height: "56px",
                }}
                onClick={() => {
                  reset();
                }}
                type="button"
              >
                Limpar campos
              </Button>
            </Grid>
          )}

          <Grid item xs={!id ? 6 : 12}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                height: "56px",
              }}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                "Salvando..."
              ) : (
                <>{id ? "Salvar" : "Cadastrar"}</>
              )}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Edit;
