import axios from "axios";
import { AUTH_USER_TOKEN } from "../constants/auth";

const apiApplogged = axios.create({
  baseURL: "https://0zfa0j6c3l.execute-api.us-east-1.amazonaws.com/prd/",
});

apiApplogged.interceptors.request.use(async (config) => {
  const token = localStorage.getItem(AUTH_USER_TOKEN);
  if (token) {
    config.headers.authorizationToken = `Bearer ${token}`;
  }
  return config;
});


export default apiApplogged;
