import {
  Button, Grid, TextField,
  Typography
} from "@mui/material";
import { useParams } from "react-router-dom";
import { PageTitle } from "../../components/molecules/PageTitle";

const Edit = () => {
  const { id } = useParams();

  return (
    <>
      <PageTitle
        title={`${id ? "Editar cliente" : "Cadastrar cliente"}`}
        breadcrumbs={[
          {
            label: "Clientes",
            path: "/clientes",
          },
          {
            label: id ? "Editar" : "Criar",
          },
        ]}
      />

      <Grid container spacing={2} marginTop={2}>
        <Grid item xs={12}>
          <TextField
            id="outlined-basic"
            label="Nome da categoria"
            variant="outlined"
            fullWidth
          />
        </Grid>
       
        <Grid item xs={6}>
          <Button
            variant="outlined"
            fullWidth
            sx={{
              height: "56px",
            }}
          >
            Limpar campos
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            fullWidth
            sx={{
              height: "56px",
            }}
          >
            Criar categoria
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Edit;
