import { Avatar, Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import { useNavigate } from "react-router-dom";
import { stringAvatar } from "../../../utils/string";

const UserItem = ({ user }) => {
  let navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        marginBottom: 1,
        cursor: "pointer",
      }}
      onClick={() => {
        if (user && user.username) {
          navigate(`/@${user.username}`);
        }
      }}
    >
      <Box>
        <Avatar {...stringAvatar(user.name)} />
      </Box>
      <Box
        sx={{
          marginLeft: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Typography>{user.name}</Typography>
        {user.username && (
          <Typography sx={{ fontSize: "0.75rem", color: grey[600] }}>
            @{user.username}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default UserItem;
