import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FolderIcon from "@mui/icons-material/Folder";
import {
  Box,
  Button,
  Fab,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PageTitle } from "../../components/molecules/PageTitle";
import DeleteDialog from "../../components/organisms/DeleteDialog/DeleteDialog";
import useCategories from "../../hooks/useCategories";
import useServices from "../../hooks/useServices";
import { deleteCategory } from "../../services/categories";
import { deleteProduct } from "../../services/products";
import ListTitleAction from "../../components/molecules/ListTitleAction";

const Home = () => {
  let navigate = useNavigate();

  const { services, refetch } = useServices();
  const {
    showCategoryName,
    categories,
    refetch: refetchCategories,
  } = useCategories();

  const [deleteItemId, setDeleteItemId] = useState(null);

  const [servicesFiltered, setServicesFiltered] = useState([]);

  const handleEdit = (id) => {
    navigate(`${id}/edit`);
  };

  const handleDelete = (id) => {
    setDeleteItemId(id);
  };

  const handleConfirmDelete = () => {
    deleteProduct(deleteItemId)
      .then(() => {
        refetch();
        toast.success("Serviço excluído com sucesso!");
        setDeleteItemId(null);
      })
      .catch(() => {
        toast.error("Erro ao excluir serviço!");
      });
  };

  const handleCancelDelete = () => {
    setDeleteItemId(null);
  };

  const [selectedCategory, setSelectedCategory] = useState(null);

  const [deleteCategoryId, setDeleteCategoryId] = useState(null);

  const handleEditCategory = (id) => {
    navigate(`/services/category/${id}/edit`);
  };

  const handleDeleteCategory = (id) => {
    setDeleteCategoryId(id);
  };

  const handleCancelDeleteCategory = () => {
    setDeleteItemId(null);
  };

  const handleConfirmDeleteCategory = () => {
    deleteCategory(deleteCategoryId)
      .then(() => {
        refetchCategories();
        toast.success("Categoria excluída com sucesso!");
        setDeleteCategoryId(null);
      })
      .catch(() => {
        toast.error("Erro ao excluir categoria!");
      });
  };

  const handleSelectCategory = (categoryId) => {
    setSelectedCategory(categoryId);

    if (!categoryId) {
      setServicesFiltered(services);
      return;
    }

    setServicesFiltered(
      services.filter((service) => service.categories.includes(categoryId))
    );
  };

  const customCategories = [
    { _id: null, name: "Todas as categorias" },
    ...categories,
  ];

  useEffect(() => {
    setServicesFiltered(services);
  }, [services]);

  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            label: "Serviços",
          },
        ]}
      />

      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <ListTitleAction
            title="Categorias"
            btnTitle="NOVA CATEGORIA"
            goTo="/services/category/create"
          />

          <List
            sx={{
              marginY: 1,
              padding: 0,
            }}
          >
            {customCategories.map((row) => (
              <ListItem
                sx={{
                  margin: 0,
                  padding: 0,
                }}
              >
                <ListItemButton
                  selected={selectedCategory === row._id}
                  onClick={() => handleSelectCategory(row._id)}
                >
                  <ListItemIcon>
                    <FolderIcon />
                  </ListItemIcon>
                  <ListItemText primary={row.name} />

                  {row._id && (
                    <>
                      <IconButton
                        color="primary"
                        aria-label="edit"
                        component="span"
                        size="small"
                        onClick={() => handleEditCategory(row._id)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="primary"
                        aria-label="delete"
                        component="span"
                        size="small"
                        onClick={() => handleDeleteCategory(row._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} md={8}>
          <ListTitleAction
            title="Serviços"
            btnTitle="NOVO SERVIÇO"
            goTo="/services/create"
          />

          <TableContainer
            component={Paper}
            sx={{
              mt: 1,
            }}
          >
            <Table sx={{ width: "100%" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>Categoria</TableCell>
                  <TableCell>Preço</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {servicesFiltered.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>
                      {row.categories
                        .map((item) => showCategoryName(item))
                        .join(", ")}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        minWidth: "80px",
                      }}
                    >
                      R$ {row.price}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        color="primary"
                        aria-label="edit"
                        component="span"
                        size="small"
                        onClick={() => handleEdit(row._id)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="primary"
                        aria-label="delete"
                        component="span"
                        size="small"
                        onClick={() => handleDelete(row._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <DeleteDialog
        open={!!deleteItemId}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title="Excluir serviço"
        description="Tem certeza que deseja excluir este serviço?"
      />

      <DeleteDialog
        open={!!deleteCategoryId}
        onClose={handleCancelDeleteCategory}
        onConfirm={handleConfirmDeleteCategory}
        title="Excluir categoria"
        description="Tem certeza que deseja excluir esta categoria?"
      />
    </>
  );
};

export default Home;
