import moment from "moment";

export function formatDateTime(dateTime) {
    const momentDateTime = moment(dateTime);
  
    if (momentDateTime.isValid()) {
      return momentDateTime.format("DD/MM/YYYY HH:mm:ss");
    } else {
      return "Data e hora inválidas";
    }
  }