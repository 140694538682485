import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import Breadcrumbs from "../../components/organisms/Breadcrumbs";
import useApp from "../../hooks/queries/useApp";
import useUserApp from "../../hooks/queries/useUserApp";
import { updateAppDomain } from "../../services/apps";
import { PageTitle } from "../../components/molecules/PageTitle";

const schema = yup
  .object({
    domain: yup.string().required("Digite o nome").min(2),
  })
  .required();

const UpdateDomain = () => {
  let navigate = useNavigate();

  const { app } = useApp();
  const { app: userApp, refetch } = useUserApp();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      domain: userApp?.domain?.toLowerCase(),
    },
  });

  const onSubmit = (data) => {
    setIsSubmitting(true);

    updateAppDomain(userApp._id, data)
      .then(() => {
        toast.success("Atualizado com sucesso!");
        refetch();
        navigate("/settings");
      })
      .catch(() => {
        toast.error("Erro ao atualizar!");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <PageTitle
        title={`Alterar domínio`}
        breadcrumbs={[
          {
            label: "Configurações",
            path: "/settings",
          },
          {
            label: "Domínio",
          },
        ]}
      />
      
      <Box
        component="form"
        sx={{ textAlign: "center" }}
        onSubmit={handleSubmit(onSubmit)}
        maxWidth="sm"
      >
        <Grid container spacing={2} marginTop={2} >
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              label="Domínio"
              variant="outlined"
              fullWidth
              {...register("domain")}
              error={!!errors.domain}
              helperText={errors.domain?.message}
              onChange={(e) => {
                setValue("domain", e.target.value.toLowerCase().trim());
              }}
              InputProps={{
                endAdornment: <>.{app?.domain}.com</>,
              }}
              // disabled={!userApp?.domain}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                height: "56px",
              }}
              disabled={isSubmitting || !userApp?.domain}
            >
              {isSubmitting ? "Salvando..." : <>Salvar</>}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default UpdateDomain;
