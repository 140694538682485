import { Avatar, ListItemText, MenuItem, MenuList, Paper } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AppTemplate from "../components/templates/AppTemplate";
import { AppContext } from "../providers/AppContext";

const Menu = () => {
  let navigate = useNavigate();

  const { user } = useContext(AppContext);

  return (
    <AppTemplate>
      <Paper sx={{ width: "100%", maxWidth: "100%" }}>
        {/* AVATAR */}
        <Box>
          <Box>
            <Avatar />
          </Box>
        </Box>

        <MenuList>
          <MenuItem onClick={() => navigate("/my-account/update-profile")}>
            <ListItemText>Alterar dados do perfil</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => navigate("/my-account/update-password")}>
            <ListItemText>Alterar senha</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => navigate("/my-account/update-email")}>
            <ListItemText>Alterar e-mail</ListItemText>
          </MenuItem>
        </MenuList>
      </Paper>
    </AppTemplate>
  );
};

export default Menu;
