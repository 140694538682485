import { DAY } from "./date";

export const NEW_APP_INITIAL_VALUES = {
  name: "",
  domain: "",
  type: "schedule",
  categories: [],
  address: null,
  employees: [],
  services: [],
  serviceLocations: [],
  operatingDays: [
    {
      day: DAY.MONDAY,
      times: [
        {
          initial: "10:00",
          final: "19:00",
        },
      ],
    },
    {
      day: DAY.TUESDAY,
      times: [
        {
          initial: "10:00",
          final: "19:00",
        },
      ],
    },
    {
      day: DAY.WEDNESDAY,
      times: [
        {
          initial: "12:00",
          final: "19:00",
        },
      ],
    },
    {
      day: DAY.THURSDAY,
      times: [
        {
          initial: "10:00",
          final: "19:00",
        },
      ],
    },
    {
      day: DAY.FRIDAY,
      times: [
        {
          initial: "10:00",
          final: "19:00",
        },
      ],
    },
    {
      day: DAY.SATURDAY,
      times: [
        {
          initial: "10:00",
          final: "19:00",
        },
      ],
    },
  ],
};
