import PageviewIcon from "@mui/icons-material/Pageview";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/organisms/Breadcrumbs";
import useCustomers from "../../hooks/queries/useCustomers";
import { PageTitle } from "../../components/molecules/PageTitle";
import ListTitleAction from "../../components/molecules/ListTitleAction";

const Home = () => {
  const { id } = useParams();
  let navigate = useNavigate();

  const { customers } = useCustomers();

  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            label: "Clientes",
          },
        ]}
      />

      <ListTitleAction
        title="Clientes"
        btnTitle="NOVO CLIENTE"
        goTo="/customers/create"
        btnDisabled={true}
      />

      <TableContainer component={Paper} sx={{mt: 1}}>
        <Table sx={{ width: "100%" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell align="right"> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    size="small"
                    onClick={() => navigate(`${row._id}`)}
                  >
                    <PageviewIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        sx={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginY: 2,
        }}
      >
        <Pagination count={3} />
      </Box>
    </>
  );
};

export default Home;
