import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import useApp from "../../hooks/queries/useApp";
import { removeAccentsAndSpecialCharacters } from "../../utils/string";
import { NewAppContext } from "./NewApp";

const schema = yup
  .object({
    domain: yup
      .string()
      .required("Campo obrigatório")
      .min(2, "Mínimo de 2 caracteres")
      .max(30, "Máximo de 30 caracteres")
      .matches(
        /^[a-zA-Z0-9-]+$/,
        "Domínio inválido. Use apenas letras, números e hífens."
      ),
  })
  .required();

const Domain = () => {
  const { values, handleValues } = useContext(NewAppContext);

  const { app } = useApp();

  const defaultValues = {
    domain:
      values?.domain.replace(app.domain, "") ||
      removeAccentsAndSpecialCharacters(values.name)
        .replaceAll(" ", "")
        .trim()
        .toLowerCase(),
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const onSubmit = (data) => {
    handleValues({
      domain: `${data.domain}.${app.domain}`,
    });
  };

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <Box sx={{ mb: 3, textAlign: "center" }}>
          <TextField
            type="text"
            name="domain"
            control={control}
            label="Domínio"
            placeholder="Digite o domínio"
            {...register("domain")}
            error={!!errors.domain}
            helperText={errors.domain?.message}
            onChange={(e) => {
              setValue("domain", e.target.value.toLowerCase().trim());
            }}
            InputProps={{
              endAdornment: <>.{app?.domain}</>,
            }}
          />
        </Box>

        <Alert severity="info" sx={{ mb: 3 }}>
          <AlertTitle>Exemplo:</AlertTitle>
          <Typography>Esse será o link do seu web aplicativo:</Typography>
          <Typography variant="body1">
            https://{watch("domain")}.{app?.domain}
          </Typography>
        </Alert>

        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{
            height: "56px",
          }}
        >
          FINALIZAR CRIAÇÃO DO APLICATIVO
        </Button>
      </Box>
    </>
  );
};

export default Domain;
