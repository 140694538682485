import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Link, Typography } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as yup from "yup";
import Button from "../components/atoms/Button";
import Title from "../components/atoms/Title/Title";
import TextField from "../components/molecules/TextField";
import AuthTemplate from "../components/templates/AuthTemplate";
import { createStore } from "../services/stores";
import ManageAppTemplate from "../components/templates/ManageAppTemplate";
import useApp from "../hooks/queries/useApp";

const schema = yup
  .object({
    name: yup.string().required("Campo obrigatório"),
    domain: yup.string().required("Campo obrigatório"),
  })
  .required();

const CreateApp = () => {
  let navigate = useNavigate();

  const { app } = useApp();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      domain: "",
    },
  });
  const onSubmit = (data) => {
    setIsSubmitted(true);
    createStore({ ...data, type: "schedule" })
      .then(() => {
        navigate("/apps");
      })
      .catch((err) => {
        setError(true);
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };
  return (
    <ManageAppTemplate>
      <Box
        component="form"
        sx={{ textAlign: "center" }}
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <Box sx={{ mb: 2 }}>
          <Title>Cadastrar estabelecimento</Title>
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            name="name"
            control={control}
            label="Nome do estabelecimento"
            placeholder="Digite o nome do estabelecimento"
            error={errors.name}
            onChange={(e) => {
              setValue("name", e.target.value);
              setValue(
                "domain",
                e.target.value.replaceAll(" ", "-").trim().toLowerCase()
              );
            }}
          />
        </Box>
        <Box sx={{ mb: 1 }}>
          <TextField
            type="text"
            name="domain"
            control={control}
            label="Domínio"
            placeholder="Digite o domínio do seu estabelecimento"
            error={errors.domain}
            onChange={(e) => {
              setValue("domain", e.target.value.toLowerCase().trim());
            }}
            InputProps={{
              endAdornment: <>.{app?.domain}.com</>,
            }}
          />
        </Box>

        <Box sx={{ display: " flex", mt: 1 }}>
          <Box sx={{ mb: 2, width: "100%" }}>
            <Button
              type="submit"
              sx={{ width: "100%" }}
              disabled={isSubmitted}
              color="primary"
            >
              CRIAR APP
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            mb: 2,
            display: " flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Link
            to="/apps"
            underline="none"
            sx={{ fontWeight: 500 }}
            component={RouterLink}
          >
            Voltar
          </Link>
        </Box>

        {error && (
          <Box sx={{ mt: 1, textAlign: "center", width: "100%" }}>
            <Typography
              variant="body2"
              sx={{ color: "#d32f2f", fontWeight: "bold", fontSize: 12 }}
            >
              Não foi possível cadastrar o estabelecimento
            </Typography>
          </Box>
        )}
      </Box>
    </ManageAppTemplate>
  );
};

export default CreateApp;
