import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, TextField } from "@mui/material";
import { useForm } from "react-hook-form";

const CustomForm = ({
  onSubmit,
  schema = null,
  defaultValues = {},
  isSubmitting,
  inputs,
  btnText = "Salvar",
  autoComplete = "off",
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  return (
    <Box
      component="form"
      sx={{ textAlign: "center" }}
      onSubmit={handleSubmit(onSubmit)}
      maxWidth="sm"
      autoComplete={autoComplete}
    >
      <Grid container spacing={2}>
        {inputs.map((input) => (
          <Grid item xs={12} {...input?.grid}>
            <TextField
              id={input.name}
              label={input.label}
              placeholder={input.placeholder}
              required={input.required}
              variant="outlined"
              fullWidth
              {...register(input.name)}
              error={!!errors[input.name]}
              helperText={errors[input.name]?.message}
            />
          </Grid>
        ))}

        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              height: "56px",
            }}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Salvando..." : <>{btnText}</>}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomForm;
