import { useQuery } from "react-query";
import { getAppById } from "../../services/apps";

const useUserApp = () => {
  const appId = localStorage.getItem("appId");

  const { data, isLoading, error, refetch } = useQuery("app_" + appId, () =>
    appId ? getAppById(appId) : null
  );

  const app = data?.data || null;
  const hasSelected = !!appId;

  return { appId, hasSelected, app, isLoading, error, refetch };
};

export default useUserApp;
