import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import EventIcon from "@mui/icons-material/Event";
import GroupIcon from "@mui/icons-material/Group";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import WorkIcon from "@mui/icons-material/Work";
import { goToLogout } from "../services/app/auth.service";

export const MENU_ITEMS = [
  {
    title: "Agenda",
    description: "Acompanhe sua agenda",
    navigate: "/schedule",
    icon: <EventIcon />,
    isDisabled: true,
  },
  {
    title: "Faturamento",
    description: "Acompanhe seu desempenho financeiro",
    navigate: "/revenue",
    icon: <AttachMoneyIcon />,
    isDisabled: true,
  },
  {
    title: "Clientes",
    description: "Gerencie sua base de clientes",
    navigate: "/customers",
    icon: <GroupIcon />,
  },
  {
    title: "Serviços",
    description: "Gerencie seus serviços",
    navigate: "/services",
    icon: <WorkIcon />,
  },
  {
    title: "Funcionários",
    description: "Formule sua equipe de trabalho",
    navigate: "/employees",
    icon: <GroupIcon />,
  },
  {
    title: "Promoções",
    description: "Crie promoções especiais",
    navigate: "/offers",
    isDisabled: true,
    icon: <AttachMoneyIcon />,
  },
  {
    title: "Configurações",
    description: "Personalize seu aplicativo",
    navigate: "/settings",
    icon: <SettingsIcon />,
  },
  {
    title: "Meus Aplicativos",
    description: "Gerencie seus aplicativos",
    navigate: "/apps",
    icon: <AttachMoneyIcon />,
  },
  {
    title: "Desconectar",
    description: "Gerencie seus aplicativos",
    icon: <LogoutIcon />,
    onClick: () => {
      goToLogout();
    },
  },
];
