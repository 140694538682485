import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { PageTitle } from "../../components/molecules/PageTitle";
import {
  createCategory,
  getCategory,
  updateCategory,
} from "../../services/categories";

const schema = yup
  .object({
    name: yup.string().required("Digite o nome da categoria"),
  })
  .required();

const EditCategory = () => {
  const { id } = useParams();
  let navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
    },
  });

  const onSubmit = (data) => {
    setIsSubmitting(true);

    if (id) {
      updateCategory(id, data)
        .then((response) => {
          toast.success("Categoria atualizada com sucesso!");
          navigate(-1);
        })
        .catch((error) => {
          toast.error("Erro ao atualizar categoria!");
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else {
      createCategory(data)
        .then((response) => {
          toast.success("Categoria criada com sucesso");
          navigate(-1);
          reset();
        })
        .catch((error) => {
          toast.error("Erro ao criar categoria");
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getCategory(id)
        .then((response) => {
          reset(response.data);
        })
        .catch((error) => {
          toast.error("Erro ao carregar categoria");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [id, reset]);

  if (isLoading) {
    return <Typography>Carregando...</Typography>;
  }

  return (
    <>
      <PageTitle
        title={`${id ? "Editar categoria" : "Cadastrar categoria"}`}
        breadcrumbs={[
          {
            label: "Serviços",
            path: "/services",
          },
          {
            label: id ? "Editar categoria" : "Criar categoria",
          },
        ]}
      />

      <Box
        component="form"
        sx={{ textAlign: "center" }}
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <Grid container spacing={2} marginTop={2}>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              label="Nome"
              variant="outlined"
              fullWidth
              {...register("name")}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          </Grid>

          {!id && (
            <Grid item xs={6}>
              <Button
                type="button"
                variant="outlined"
                fullWidth
                sx={{
                  height: "56px",
                }}
                onClick={() => {
                  reset();
                }}
              >
                Limpar campos
              </Button>
            </Grid>
          )}

          <Grid item xs={!id ? 6 : 12}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                height: "56px",
              }}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                "Salvando..."
              ) : (
                <>{id ? "Salvar" : "Cadastrar"}</>
              )}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EditCategory;
