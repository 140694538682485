import { Container } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const ManageAppTemplate = ({ children }) => {
  return (
    <Container
      sx={{
        paddingLeft: 0,
        paddingRight: 0,
      }}
    >
      {/* <AppBar position="sticky">
        <Toolbar>
          <Typography noWrap component="div" onClick={() => navigate("/")}>
            {app.name}
          </Typography>
        </Toolbar>
      </AppBar> */}

      {children}
    </Container>
  );
};

ManageAppTemplate.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ManageAppTemplate;
