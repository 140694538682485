import api from "../config/api";
import apiApplogged from "../config/api-app-logged";
import { STORES_PATH } from "../constants/store";

export const getCustomers = () => {
  return apiApplogged.get(`${STORES_PATH}/customers`);
};

export const getCustomer = (id) => {
  return apiApplogged.get(`${STORES_PATH}/customers/${id}`);
};

export const createCustomer = (data) => {
  return apiApplogged.post(`${STORES_PATH}/customers`, data);
};

export const updateCustomer = (id, data) => {
  return apiApplogged.put(`${STORES_PATH}/customers/${id}`, data);
};

export const deleteCustomer = (id) => {
  return apiApplogged.delete(`${STORES_PATH}/customers/${id}`);
};
