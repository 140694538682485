import { Box, Grid } from "@mui/material";
import { CardAction } from "../components/molecules/CardAction";
import AppTemplate from "../components/templates/AppTemplate";
import { MENU_ITEMS } from "../constants/menu-items";

const HomeLogged = () => {
  return (
    <AppTemplate>
      {/* <Grid
        container
        spacing={1}
        sx={{
          marginBottom: 3,
        }}
      >
        <Grid item xs={12}>
          <Alert severity="warning">10 AGENDAMENTOS PENDENTES</Alert>
        </Grid>
      </Grid> */}

      <Grid container spacing={3}>
        {MENU_ITEMS.map((item) => (
          <Grid item xs={6} md={3} key={item.title}>
            <CardAction item={item} />
          </Grid>
        ))}
      </Grid>

      <Box sx={{ marginTop: "2rem" }} />
    </AppTemplate>
  );
};

export default HomeLogged;
