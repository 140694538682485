import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import { Box, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import AppTemplate from "../components/templates/AppTemplate";

const Page404 = () => {
  return (
    <AppTemplate>
      <Box sx={{ textAlign: "center" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography sx={{ fontSize: 100 }}>4</Typography>
          <SentimentDissatisfiedIcon sx={{ fontSize: 100 }} />
          <Typography sx={{ fontSize: 100 }}>4</Typography>
        </Box>{" "}
        <h2>Página não encontrada</h2>

        <Link
            to="/"
            sx={{ fontWeight: 500 }}
            component={RouterLink}
          >
            Voltar ao início
          </Link>
      </Box>
    </AppTemplate>
  );
};

export default Page404;
