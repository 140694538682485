import { useQuery } from "react-query";
import { getCustomers } from "../../services/customers";

const useCustomers = () => {
  const { data, isLoading, error, refetch } = useQuery("customers", () =>
    getCustomers()
  );

  const customers = data?.data || [];

  return { customers, isLoading, error, refetch };
};

export default useCustomers;
