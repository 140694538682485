import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getCustomer } from "../../services/customers";
import UserAvatar from "../../components/atoms/UserAvatar/UserAvatar";
import { formatDateTime } from "../../utils/date";
import { PageTitle } from "../../components/molecules/PageTitle";

const View = () => {
  let navigate = useNavigate();
  const { id } = useParams();

  const { data, isLoading, error, refetch } = useQuery("customer" + id, () =>
    getCustomer(id)
  );

  if (isLoading) return <>Carregando...</>;

  const customer = data?.data || null;

  return (
    <>
      <PageTitle
        title={`Dados do cliente`}
        breadcrumbs={[
          {
            label: "Clientes",
            path: "/clientes",
          },
          {
            label: "Dados do clientes"
          },
        ]}
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box sx={{ flex: 1 }}></Box>
            <Button onClick={() => navigate(`/clientes/${id}/edit`)}>
              EDITAR DADOS
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ width: "100%" }}>
            <CardHeader
              avatar={<UserAvatar name={"moises"} />}
              title={customer?.name}
              subheader={`Cadastrado em: ${formatDateTime(
                customer?.createdAt
              )}`}
            />
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                Email: {customer?.email}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Telefone: {customer?.phone}
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              {customer?.phone && (
                <Button
                  onClick={() =>
                    window.open(`http://wa.me/5511${customer?.phone}`, "_blank")
                  }
                >
                  ENVIAR WHATSAPP
                </Button>
              )}
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default View;
