import { Grid } from "@mui/material";
import { CardAction } from "../../components/molecules/CardAction";
import { PageTitle } from "../../components/molecules/PageTitle";

const Arr = [
  {
    title: "Nome do app",
    description: "Nome do seu estabelecimento",
    navigate: "/settings/name",
  },
  {
    title: "Endereço",
    description: "Configure seu endereço",
    navigate: "/settings/address",
  },
  {
    title: "Telefones",
    description: "Configure seus telefones",
    navigate: "/settings/phones",
  },
  {
    title: "Domínio",
    description: "Configure o domnínio",
    navigate: "/settings/domain",
  },
  {
    title: "Personalizar",
    description: "Altere as cores do seu app",
    navigate: "/settings/personalize",
  },
];

const Home = () => {
  return (
    <>
      <PageTitle
        title={`Configurações`}
        breadcrumbs={[
          {
            label: "Configurações",
          },
        ]}
      />

      <Grid container spacing={3}>
        {Arr.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item.title}>
            <CardAction item={item} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Home;
