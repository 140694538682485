import { useQuery } from "react-query";
import { AUTH_USER_TOKEN } from "../../constants/auth";
import { getCurrentUser } from "../../services/user";

const useUser = () => {
  const isLogged = !!localStorage.getItem(AUTH_USER_TOKEN);

  const { data, isLoading, error, refetch } = useQuery("CURRENT_USER", () =>
    isLogged ? getCurrentUser() : null
  );

  const user = data?.data || null;

  return { user, isLogged, isLoading, error, refetch };
};

export default useUser;
