import { Navigate, Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "../components/organisms/ProtectedRoute";
import useUser from "../hooks/queries/useUser";
import useUserApp from "../hooks/queries/useUserApp";
import AccessDanied from "../pages/AccessDanied";
import Auth from "../pages/Auth";
import CreateApp from "../pages/CreateApp";
import Customers, {
  CustomersEdit,
  CustomersHome,
  CustomersView,
} from "../pages/Customers";
import Customize from "../pages/Customize";
import Employees, {
  EmployeeEdit,
  EmployeeHome,
  EmployeeView,
} from "../pages/Employees";
import Home from "../pages/Home";
import HomeLogged from "../pages/HomeLogged";
import Menu from "../pages/Menu";
import NewApp, {
  NewAppAddress,
  NewAppCategory,
  NewAppDomain,
  NewAppEmployees,
  NewAppFinish,
  NewAppName,
  NewAppOpering,
  NewAppPlaces,
  NewAppServices,
} from "../pages/NewApp";
import Orders from "../pages/Orders";
import Page404 from "../pages/Page404";
import Schedule from "../pages/Schedule";
import Services, {
  ServicesEdit,
  ServicesEditCategory,
  ServicesHome,
  ServicesView,
} from "../pages/Services";
import Settings, {
  SettingsHome,
  SettingsUpdateAddress,
  SettingsUpdateDomain,
  SettingsUpdateName,
  SettingsUpdatePhones,
  SettingsUpdateStyles,
} from "../pages/Settings";
import UserApps from "../pages/UserApps";
import Users from "../pages/Users";

export function AppRouter() {
  const { isLogged } = useUser();
  const { hasSelected } = useUserApp();

  return (
    <Routes>
      <Route
        path="/"
        exact
        element={!isLogged ? <Home /> : <Navigate to="/home" />}
      />
      <Route path="auth" element={<Auth />} />

      <Route element={<ProtectedRoute isAllowed={isLogged} />}>
        <Route path="apps" element={<UserApps />} />
        <Route path="criar-app" element={<CreateApp />} />

        <Route path="/new-app" element={<NewApp />}>
          <Route path="name" element={<NewAppName />} />
          <Route path="category" element={<NewAppCategory />} />
          <Route path="places" element={<NewAppPlaces />} />
          <Route path="address" element={<NewAppAddress />} />
          <Route path="operating" element={<NewAppOpering />} />
          <Route path="employees" element={<NewAppEmployees />} />
          <Route path="services" element={<NewAppServices />} />
          <Route path="domain" element={<NewAppDomain />} />
          <Route path="finish" element={<NewAppFinish />} />
        </Route>

        <Route
          element={
            <ProtectedRoute isAllowed={hasSelected} redirectPath="/apps" />
          }
        >
          <Route path="home" exact element={<HomeLogged />} />
          <Route path="personalizar" element={<Customize />} />
          <Route path="pedidos" element={<Orders />} />
          <Route path="agenda" element={<Schedule />} />

          <Route path="services" element={<Services />}>
            <Route index element={<ServicesHome />} />
            <Route path=":id" element={<ServicesView />} />
            <Route path="create" element={<ServicesEdit />} />
            <Route path=":id/edit" element={<ServicesEdit />} />
            <Route path="category/create" element={<ServicesEditCategory />} />
            <Route
              path="category/:id/edit"
              element={<ServicesEditCategory />}
            />
          </Route>

          <Route path="customers" element={<Customers />}>
            <Route index element={<CustomersHome />} />
            <Route path="create" element={<CustomersEdit />} />
            <Route path=":id" element={<CustomersView />} />
            <Route path=":id/edit" element={<CustomersEdit />} />
          </Route>

          <Route path="employees" element={<Employees />}>
            <Route index element={<EmployeeHome />} />
            <Route path="create" element={<EmployeeEdit />} />
            <Route path=":id/edit" element={<EmployeeEdit />} />
            <Route path=":id/view" element={<EmployeeView />} />
          </Route>

          <Route path="offers" element={<Customers />}>
            <Route index element={<CustomersHome />} />
            <Route path="create" element={<CustomersEdit />} />
            <Route path=":id/edit" element={<CustomersEdit />} />
          </Route>

          <Route path="settings" element={<Settings />}>
            <Route index element={<SettingsHome />} />
            <Route path="name" element={<SettingsUpdateName />} />
            <Route path="domain" element={<SettingsUpdateDomain />} />
            <Route path="address" element={<SettingsUpdateAddress />} />
            <Route path="phones" element={<SettingsUpdatePhones />} />
            <Route path="personalize" element={<SettingsUpdateStyles />} />
          </Route>

          <Route path="menu" element={<Menu />} />
          <Route path="users" element={<Users />} />
        </Route>
      </Route>

      {/* 404 */}
      <Route path="access-denied" element={<AccessDanied />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}
