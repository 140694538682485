import React, { useEffect } from "react";
import { AUTH_USER_TOKEN } from "../constants/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import useUser from "../hooks/queries/useUser";

const Auth = () => {
  let navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const { refetch } = useUser();

  useEffect(() => {
    localStorage.setItem(AUTH_USER_TOKEN, token);

    refetch()
      .then(() => {
        navigate("/apps");
      })
      .catch(() => {
        alert("Algo de errado aconteceu!");
      });
  }, []);

  return <>Conectando...</>;
};

export default Auth;
