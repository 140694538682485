import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { PageTitle } from "../../components/molecules/PageTitle";
import {
  createEmployee,
  getEmployee,
  updateEmployee,
} from "../../services/employees";
import { formatPhoneNumber } from "../../utils/string";

const schema = yup
  .object({
    name: yup.string().required("Campo obrigatório"),
    email: yup.string().email("E-mail inválido").required("Campo obrigatório"),
    phone: yup.string(),
  })
  .required();

const Edit = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      email: "",
      phone: "",
    },
  });

  const onSubmit = (data) => {
    setIsSubmitting(true);

    if (id) {
      updateEmployee(id, data)
        .then((response) => {
          toast.success("Funcionário atualizado com sucesso!");
          navigate("/employees");
        })
        .catch((error) => {
          toast.error("Erro ao atualizar funcionário!");
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else {
      createEmployee({
        ...data,
        type: "EMPLOYEE",
      })
        .then((response) => {
          toast.success("Funcionário criado com sucesso!");
          reset();
          navigate("/employees");
        })
        .catch((error) => {
          toast.error("Erro ao criar funcionário!");
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getEmployee(id)
        .then((response) => {
          const data = response.data;

          reset({
            ...data,
            type: "service",
          });
        })
        .catch((error) => {
          toast.error("Erro ao carregar serviço!");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [id, reset]);

  if (isLoading) {
    return <Typography>Carregando...</Typography>;
  }

  return (
    <>
      <PageTitle
        title={`${id ? "Editar funcionário" : "Criar funcionário"}`}
        breadcrumbs={[
          {
            label: "Funcionários",
            path: "/employees",
          },
          {
            label: id ? "Editar" : "Criar",
          },
        ]}
      />

      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <Grid container spacing={2} marginTop={2}>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              label="Nome"
              variant="outlined"
              fullWidth
              {...register("name")}
              error={!!errors.name}
              helperText={errors.name?.message}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              label="E-mail"
              type="email"
              variant="outlined"
              fullWidth
              {...register("email")}
              error={!!errors.email}
              helperText={errors.email?.message}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              label="Telefone"
              variant="outlined"
              fullWidth
              {...register("phone")}
              error={!!errors.phone}
              helperText={errors.phone?.message}
              onChange={(e) => {
                e.target.value = formatPhoneNumber(e.target.value);
              }}
            />
          </Grid>

          {!id && (
            <Grid item xs={6}>
              <Button
                variant="outlined"
                fullWidth
                sx={{
                  height: "56px",
                }}
                onClick={() => {
                  reset();
                }}
                type="button"
              >
                Limpar campos
              </Button>
            </Grid>
          )}

          <Grid item xs={!id ? 6 : 12}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                height: "56px",
              }}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                "Salvando..."
              ) : (
                <>{id ? "Salvar" : "Cadastrar"}</>
              )}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Edit;
