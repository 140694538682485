import { Helmet } from "react-helmet";
import useApp from "../../hooks/queries/useApp";

const PageTags = () => {
  const { app, styles } = useApp();

  const style = styles.length > 0 ? styles[0] : null;

  if (!app) return <></>;

  const title = `${app.name} - Painel`;

  return (
    <Helmet>
      <title>{title}</title>
      {app.description && (
        <meta name="description" content="Some description." />
      )}
      <meta property="og:title" content={title} />

      {style && <meta name="theme-color" content={style.primaryColor} />}
    </Helmet>
  );
};

export default PageTags;
