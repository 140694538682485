import { AUTH_DOMAIN_NAME, AUTH_USER_TOKEN } from "../../constants/auth";

export const goToAuth = (entry) => {
  const appDomain =
    localStorage.getItem(AUTH_DOMAIN_NAME) || window.location.host;
  const params = `?domainName=${appDomain.replace("painel.", "")}&redirectUrl=${
    window.location.host
  }&theme=light&entry=${entry}`;
  const authPage = "https://acesso.appzei.com/";

  localStorage.removeItem(AUTH_USER_TOKEN);

  window.location.href = `${authPage}${params}`;
};

export const goToLogout = () => {
  const appDomain =
    localStorage.getItem(AUTH_DOMAIN_NAME) || window.location.host;
  const params = `logout?domainName=${appDomain.replace(
    "painel.",
    ""
  )}&redirectUrl=${window.location.host}&theme=light`;
  const authPage = "https://acesso.appzei.com/";

  localStorage.removeItem(AUTH_USER_TOKEN);

  window.location.href = `${authPage}${params}`;
};
