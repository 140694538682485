import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { NewAppContext } from "./NewApp";
import { DAY } from "../../constants/date";
import TimesDialog from "../../components/organisms/Dialogs/TimesDialog";

const daysOfWeek = [
  {
    day: DAY.MONDAY,
    title: "Segunda-feira",
  },
  {
    day: DAY.TUESDAY,
    title: "Terça-feira",
  },
  {
    day: DAY.WEDNESDAY,
    title: "Quarta-feira",
  },
  {
    day: DAY.THURSDAY,
    title: "Quinta-feira",
  },
  {
    day: DAY.FRIDAY,
    title: "Sexta-feira",
  },
  {
    day: DAY.SATURDAY,
    title: "Sábado",
  },
  {
    day: DAY.SUNDAY,
    title: "Domingo",
  },
];

const Opering = () => {
  const { values, handleValues } = useContext(NewAppContext);

  const [selectedList, setSelectedList] = useState(values.operatingDays || []);
  const [selectedDay, setSelectedDay] = useState(null);

  const onSubmit = () => {
    handleValues({
      operatingDays: selectedList,
    });
  };

  const findItem = (day) => selectedList.find((item) => item.day === day);
  const isSeleted = (day) => !!findItem(day);

  const handleSelect = (day) => {
    const founded = selectedList.find((item) => item.day === day);

    if (founded) {
      setSelectedList((current) => current.filter((item) => item.day !== day));
    } else {
      setSelectedList((current) => [
        ...current,
        {
          day: day,
          times: [
            {
              initial: "10:00",
              final: "19:00",
            },
          ],
        },
      ]);
    }
  };

  const handleClickEdit = (item) => {
    setSelectedDay({
      ...item,
      ...findItem(item.day),
    });
  };

  const handleCloseEdit = () => {
    setSelectedDay(null);
  };

  const handleChangeDayTimes = (day, times) => {
    setSelectedList((current) =>
      current.map((item) => {
        if (item.day === day) {
          return {
            day,
            times,
          };
        }

        return item;
      })
    );
  };

  return (
    <>
      <FormGroup>
        {daysOfWeek.map((item, index) => (
          <Box key={item._id}>
            <Box
              sx={{
                py: 2,
                display: "flex",
              }}
            >
              <Box
                sx={{
                  width: {
                    xs: "155px",
                    md: "170px",
                  },
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => handleSelect(item.day)}
              >
                <FormControlLabel
                  control={
                    <Switch defaultChecked checked={isSeleted(item.day)} />
                  }
                  label={
                    <Typography
                      sx={{
                        fontSize: {
                          xs: 14,
                          md: 16,
                        },
                      }}
                    >
                      {item.title}
                    </Typography>
                  }
                />
              </Box>
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                    paddingRight: 3,
                    flexDirection: "column",
                  }}
                >
                  {isSeleted(item.day) ? (
                    <Box>
                      {findItem(item.day)?.times.map((time) => (
                        <Typography
                          fontSize={14}
                          variant="body1"
                          textAlign="right"
                          marginTop={1}
                        >
                          {time.initial} - {time.final}
                        </Typography>
                      ))}
                    </Box>
                  ) : (
                    <Box>
                      <Typography
                        fontSize={14}
                        variant="body1"
                        textAlign="right"
                        marginTop={1}
                      >
                        Fechado
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "right",
                  }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    disabled={!isSeleted(item.day)}
                    onClick={() => handleClickEdit(item)}
                  >
                    ALTERAR
                  </Button>
                </Box>
              </Box>
            </Box>
            <Divider />
          </Box>
        ))}
      </FormGroup>

      <Grid item xs={12}>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{
            height: "56px",
          }}
          onClick={onSubmit}
          disabled={selectedList.length === 0}
        >
          CONTINUAR
        </Button>
      </Grid>

      {selectedDay && (
        <TimesDialog
          handleClose={handleCloseEdit}
          handleChangeDayTimes={handleChangeDayTimes}
          selectedDay={selectedDay}
        />
      )}
    </>
  );
};

export default Opering;
