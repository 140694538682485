import { Box, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AuthTemplate from "../components/templates/AuthTemplate";
import { goToAuth } from "../services/app/auth.service";

const Home = () => {
  let navigate = useNavigate();

  return (
    <AuthTemplate>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => {
              goToAuth("login");
            }}
          >
            Entrar
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            color="primary"
            variant="outlined"
            fullWidth
            onClick={() => {
              goToAuth("register");
            }}
          >
            Cadastrar
          </Button>
        </Grid>
      </Grid>
    </AuthTemplate>
  );
};

export default Home;
