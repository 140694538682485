import { Box, Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import ColorPicker from "react-pick-color";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PageTitle } from "../../components/molecules/PageTitle";
import useUserApp from "../../hooks/queries/useUserApp";
import { updateAppStyles } from "../../services/apps";

const UpdateStyles = () => {
  let navigate = useNavigate();

  const { app: userApp, refetch } = useUserApp();

  const style = userApp.styles.length > 0 ? userApp.styles[0] : null;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [primaryColor, setPrimaryColor] = useState("#fff");
  const [backgroundColor, setBackgroundColor] = useState("#ff0");
  const [textColor, setTextColor] = useState("#000");

  useEffect(() => {
    if (style) {
      setPrimaryColor(style.primaryColor);
      setBackgroundColor(style.backgroundColor);
      setTextColor(style.textColor);
    }
  }, [style]);

  const onSubmit = () => {
    setIsSubmitting(true);

    const themes = [
      {
        primaryColor,
        secondaryColor: "#D3D3D3",
        backgroundColor,
        textColor,
        type: "light",
      },
    ];

    updateAppStyles(userApp._id, themes)
      .then(() => {
        toast.success("Atualizado com sucesso!");
        refetch();
        navigate("/settings");
      })
      .catch(() => {
        toast.error("Erro ao atualizar!");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <PageTitle
        title={`Personalizar`}
        breadcrumbs={[
          {
            label: "Configurações",
            path: "/settings",
          },
          {
            label: "Personalizar",
          },
        ]}
      />

      <Grid container spacing={2} marginTop={2} maxWidth="sm">
        <Grid item xs={12}>
          <Box sx={{ textAlign: "center" }}>
            <Box
              sx={{
                backgroundColor: primaryColor,
              }}
            >
              <Box
                sx={{
                  border: "1px solid #ccc",
                  padding: 1,
                  backgroundColor: "#DCDCDC",
                  color: "#000",
                }}
              >
                COR DO APLICATIVO
              </Box>
              <Box
                sx={{
                  paddingY: 3,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ColorPicker
                  color={primaryColor}
                  onChange={(color) => setPrimaryColor(color.hex)}
                  hideAlpha={true}
                  presets={["crimson", "darkgreen", "navy"]}
                  combinations={["analogous", "complement", "monochromatic"]}
                />
              </Box>
            </Box>

            <Box
              sx={{
                backgroundColor: textColor,
              }}
            >
              <Box
                sx={{
                  border: "1px solid #ccc",
                  padding: 1,
                  backgroundColor: "#DCDCDC",
                  color: "#000",
                }}
              >
                COR DO TEXTO
              </Box>
              <Box
                sx={{
                  paddingY: 3,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ColorPicker
                  color={textColor}
                  onChange={(color) => setTextColor(color.hex)}
                  hideAlpha={true}
                  presets={["crimson", "darkgreen", "navy"]}
                  combinations={["analogous", "complement", "monochromatic"]}
                />
              </Box>
            </Box>

            <Box
              sx={{
                backgroundColor: backgroundColor,
              }}
            >
              <Box
                sx={{
                  border: "1px solid #ccc",
                  padding: 1,
                  backgroundColor: "#DCDCDC",
                  color: "#000",
                }}
              >
                COR DE FUNDO
              </Box>
              <Box
                sx={{
                  paddingY: 3,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ColorPicker
                  color={backgroundColor}
                  onChange={(color) => setBackgroundColor(color.hex)}
                  hideAlpha={true}
                  presets={["crimson", "darkgreen", "navy"]}
                  combinations={["analogous", "complement", "monochromatic"]}
                />
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              height: "56px",
            }}
            disabled={isSubmitting}
            onClick={onSubmit}
          >
            {isSubmitting ? "Salvando..." : <>Salvar</>}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default UpdateStyles;
