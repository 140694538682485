import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext } from "react";
import useUser from "../../hooks/queries/useUser";
import { NewAppContext } from "./NewApp";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import { CATEGORIES } from "../../constants/category";
import ServicesDialog from "../../components/organisms/Dialogs/ServicesDialog";

const findCategory = (key) => CATEGORIES.find((item) => item.key === key);

const Services = () => {
  const { values, handleValues } = useContext(NewAppContext);

  const [selectedService, setSelectedService] = useState(null);
  const [isOpenEdit, setIsOpenEdit] = useState(false);

  const [services, setServices] = useState(
    values.services.length > 0
      ? values.services
      : findCategory(values.categories[0]).services.map((item, index) => ({
          id: index + 1,
          ...item,
        }))
  );

  const onSubmit = () => {
    handleValues({
      services,
    });
  };

  const handleClickCreate = () => {
    setIsOpenEdit(true);
    setSelectedService(null);
  };

  const handleClickEdit = (item) => {
    setSelectedService(item);
    setIsOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setSelectedService(null);
    setIsOpenEdit(false);
  };

  const generateId = () => {
    if (services.length > 0) {
      return services[services.length - 1].id + 1;
    } else {
      return 1;
    }
  };

  const handleRemove = (id) => {
    setServices((current) => current.filter((item) => item.id !== id));
  };

  const handleChangeServices = (data) => {
    if (data.id) {
      setServices((current) =>
        current.map((item) => {
          if (item.id === data.id) {
            return data;
          }

          return item;
        })
      );
    } else {
      setServices((current) => [
        ...current,
        {
          id: generateId(),
          ...data,
        },
      ]);
    }
  };

  return (
    <>
      {services.map((item) => (
        <Box key={item.id}>
          <Card sx={{ border: 0 }} variant="outlined">
            <CardHeader
              avatar={<></>}
              action={
                <>
                  <Tooltip title="Editar serviço">
                    <IconButton
                      aria-label="edit"
                      onClick={() => handleClickEdit(item)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Remover serviço">
                    <IconButton
                      aria-label="remove"
                      onClick={() => handleRemove(item.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </>
              }
              title={`${item.name}`}
              subheader={`R$ ${item.price.toFixed(2)} | ${
                item.hours > 0 ? `${item.hours}h` : ""
              } ${item.minutes > 0 ? `${item.minutes}min` : ""} `}
            />
          </Card>
          <Divider />
        </Box>
      ))}

      <Box>
        <Card sx={{ mb: 1, border: 0 }} variant="outlined">
          <CardContent>
            <Button
              type="button"
              variant="outlined"
              fullWidth
              startIcon={<AddIcon />}
              onClick={handleClickCreate}
            >
              ADICIONAR SERVIÇO
            </Button>
            <Typography variant="body1" fontSize={12} textAlign="center" mt={2}>
              Você poderá adicionar os serviços posteriomente
            </Typography>
          </CardContent>
        </Card>
      </Box>

      <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          sx={{
            height: "56px",
          }}
          onClick={onSubmit}
        >
          CONTINUAR
        </Button>
      </Grid>

      {isOpenEdit && (
        <ServicesDialog
          handleClose={handleCloseEdit}
          selectedService={selectedService}
          handleChangeServices={handleChangeServices}
        />
      )}
    </>
  );
};

export default Services;
