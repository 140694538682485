import { Outlet } from "react-router-dom";
import ContainerCard from "../../components/atoms/ContainerCard";
import AppTemplate from "../../components/templates/AppTemplate";

const Services = () => {
  return (
    <AppTemplate>
      <ContainerCard>
        <Outlet />
      </ContainerCard>
    </AppTemplate>
  );
};

export default Services;
