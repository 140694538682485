import TextFieldMui from "@mui/material/TextField";
import PropTypes from "prop-types";
import React from "react";
import { Controller } from "react-hook-form";

const TextField = (props) => {
  const { name, control, error, ...rest } = props;

  let fieldProps = {};

  if (error) {
    fieldProps = {
      ...fieldProps,
      error: true,
      helperText: error.message,
    };
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextFieldMui fullWidth={true} {...field} {...rest} {...fieldProps}  />
      )}
    />
  );
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
};

export default TextField;
