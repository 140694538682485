import { Box, Button, Grid, LinearProgress, Typography } from "@mui/material";
import { NewAppContext } from "./NewApp";
import { useContext } from "react";
import imgWalking from "../../assets/images/walking.gif";

const Finish = () => {
  const { isLoadingCreation, app, error } = useContext(NewAppContext);

  const setAppAndNavigate = () => {
    localStorage.setItem("appId", app._id);
    window.location.href = "/home";
  };

  if (isLoadingCreation)
    return (
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Typography variant="h5">
          Seu aplicativo está sendo criado...
        </Typography>
        <Box>
          <img src={imgWalking} alt="loading" />
          <LinearProgress />
        </Box>
      </Box>
    );

  if (error)
    return (
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Typography variant="h5">Desculpe, algo deu errado...</Typography>
        <Typography variant="body1">
          Não se preocupe, em breve entraremos em contato.
        </Typography>
      </Box>
    );

  return (
    <>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Typography variant="h5">
          Parabéns! Seu aplicativo foi criado com sucesso.
        </Typography>
        <Typography variant="body1" mt={2}>
          Agora você pode gerenciar tudo no nosso painel.
        </Typography>

        <Box my={3} />

        <Button
          variant="contained"
          fullWidth
          sx={{
            height: "56px",
          }}
          onClick={setAppAndNavigate}
        >
          Acessar o Painel
        </Button>
      </Box>
    </>
  );
};

export default Finish;
