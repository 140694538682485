import { Alert, Button, Dialog, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const DeleteDialog = ({ open, onClose, onConfirm, title, description }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {title}
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          {description}
        </Typography>

        <Alert
          severity="error"
          sx={{
            my: 2,
          }}
        >
          Essa ação não poderá ser desfeita.
        </Alert>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button variant="contained" onClick={onConfirm} sx={{ ml: 2 }}>
            Confirmar
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DeleteDialog;
