import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Title from "../components/atoms/Title";
import AppTemplate from "../components/templates/AppTemplate";

const Orders = () => {
  let navigate = useNavigate();

  const [list, setList] = useState([
    {
      id: 1,
      name: "Marcio Rodrigues",
      status: "Pendente",
    },
    {
      id: 2,
      name: "Vera Lucia",
      status: "Pendente",
    },
    {
      id: 3,
      name: "João Pedro",
      status: "Andamento",
    },
    {
      id: 4,
      name: "Maria Joaquina",
      status: "Entrega",
    },
    {
      id: 5,
      name: "Joana Maria",
      status: "Concluído",
    },
  ]);

  const setStoreAndNavigate = (store) => {
    localStorage.setItem("store", JSON.stringify(store));
    localStorage.setItem("theme-color", store.color);
    window.location.href = "/";
  };

  return (
    <AppTemplate>
      <>
        <Box sx={{ mb: 2 }}>
          <Title>Agendamentos pendentes</Title>
        </Box>
        <Box sx={{ marginBottom: "1rem" }}>
          <Grid container spacing={2} marginBottom={2}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                }}
                onClick={() => navigate("/nova-loja")}
              >
                NOVO AGENDAMENTO
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {list.map((item) => (
              <Grid item xs={12} md={4}>
                <Card sx={{ width: "100%" }}>
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        sx={{
                          flex: 1,
                        }}
                      >
                        {item.name}
                      </Typography>
                      <Typography gutterBottom variant="h5" component="div">
                        #{item.id}
                      </Typography>
                    </Box>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Corte - R$ 30,00 <br />
                      Barba - R$ 10,00 <br />
                    </Typography>

                    {item.status === "Pendente" && (
                      <CardActions>
                        <Button size="small">ACEITAR PEDIDO</Button>
                        <Button size="small" color="error">
                          RECUSAR
                        </Button>
                      </CardActions>
                    )}

                    {item.status === "Concluído" && (
                      <CardActions>PEDIDO CONCLUIDO</CardActions>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}

            {list.length === 0 && (
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    fontSize: 16,
                    textAlign: "center",
                    marginTop: "1rem",
                  }}
                >
                  Nenhum pedido encontrado
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </>
    </AppTemplate>
  );
};

export default Orders;
