import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const CardAction = ({ item }) => {
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        width: "100%",
        height: 130,
        display: "flex",
        flexDirection: "column",
        padding: 1.5,
      }}
      variant="outlined"
    >
      <CardContent
        sx={{
          flex: 1,
          padding: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{
              fontSize: 16,
              flex: 1,
            }}
          >
            {item.title}
          </Typography>
        </Box>

        <Typography
          color="text.secondary"
          sx={{
            fontSize: 12,
          }}
        >
          {item.description}
        </Typography>
      </CardContent>
      <CardActions
        sx={{
          padding: 0,
        }}
      >
        <Button
          size="small"
          fullWidth
          variant="outlined"
          disabled={item.isDisabled}
          onClick={item.onClick ? item.onClick : () => navigate(item.navigate)}
        >
          {item.isDisabled ? "EM BREVE" : "ACESSAR"}
        </Button>
      </CardActions>
    </Card>
  );
};

export default CardAction;
