import apiApplogged from "../config/api-app-logged";
import { STORES_PATH } from "../constants/store";

export const getCategories = () => {
  return apiApplogged.get(`${STORES_PATH}/categories`);
};

export const getCategory = (categoryId) => {
  return apiApplogged.get(`${STORES_PATH}/categories/${categoryId}`);
};

export const createCategory = (category) => {
  return apiApplogged.post(`${STORES_PATH}/categories`, category);
};

export const updateCategory = (categoryId, category) => {
  return apiApplogged.put(`${STORES_PATH}/categories/${categoryId}`, category);
};

export const deleteCategory = (categoryId) => {
  return apiApplogged.delete(`${STORES_PATH}/categories/${categoryId}`);
};
