import axios from "axios";
import { AUTH_USER_TOKEN } from "../constants/auth";

const apiUser = axios.create({
  baseURL: "https://nplnor6m1k.execute-api.us-east-1.amazonaws.com/prd/",
});

apiUser.interceptors.request.use(async (config) => {
  const token = localStorage.getItem(AUTH_USER_TOKEN);
  if (token) {
    config.headers.authorizationToken = `Bearer ${token}`;
  }
  return config;
});

export default apiUser;
