import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import FormDialog from "../FormDialog/FormDialog";

const schema = yup
  .object({
    name: yup.string().required("Digite o nome do serviço"),
    hours: yup.string().required(),
    minutes: yup.string().required(),
    price: yup
      .number()
      .typeError("Digite um preço válido")
      .required("Digite o preço do serviço"),
  })
  .test(
    "at-least-one-field",
    "Pelo menos um campo deve ter um valor maior que zero",
    function (values) {
      const { hours, minutes } = values;

      if (!hours && !minutes) {
        return this.createError({
          path: "hours",
          message: "Horas deve ser maior que zero",
        });
      }

      return true;
    }
  )
  .required();

const ServicesDialog = ({
  handleClose,
  selectedService,
  handleChangeServices,
}) => {
  const defaultValues = selectedService || {
    name: "",
    price: "",
    hours: "",
    minutes: "",
  };

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = (data) => {
    handleChangeServices({ ...selectedService, ...data });
    handleClose();
  };

  return (
    <FormDialog
      open={true}
      title={`${selectedService ? "Editar serviço" : "Cadastrar serviço"}`}
      handleClose={handleClose}
    >
      <Alert severity="info">
        Comece adicionando informações essenciais para este serviço. Você terá a
        oportunidade de inserir uma descrição detalhada e ajustar configurações
        avançadas mais tarde.
      </Alert>

      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <Grid container spacing={2} marginTop={2}>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              label="Nome do serviço"
              variant="outlined"
              fullWidth
              {...register("name")}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3} mt={0.1} mb={4}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Duração do serviço</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="hours"
                  label="Horas"
                  variant="outlined"
                  fullWidth
                  {...register("hours")}
                  select
                  error={!!errors.hours}
                  value={watch("hours")}
                >
                  {Array.from({ length: 12 }).map((_, value) => (
                    <MenuItem key={value} value={value}>
                      {value}h
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="minutes"
                  label="Minutos"
                  variant="outlined"
                  fullWidth
                  {...register("minutes")}
                  select
                  error={!!errors.minutes}
                  value={watch("minutes")}
                >
                  {Array.from({ length: 12 }).map((_, value) => (
                    <MenuItem key={value} value={value * 5}>
                      {value * 5}min
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined" error={!!errors.category}>
              <InputLabel htmlFor="price">Preço</InputLabel>
              <OutlinedInput
                id="price"
                startAdornment={
                  <InputAdornment position="start">R$</InputAdornment>
                }
                label="Preço"
                {...register("price")}
                error={!!errors.price}
              />
              <FormHelperText>{errors.price?.message}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                height: "56px",
              }}
            >
              {`${selectedService ? "Editar" : "Cadastrar"}`}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </FormDialog>
  );
};

export default ServicesDialog;
