import { useQuery } from "react-query";
import { getProducts } from "../services/products";

const useServices = () => {
  const { data, isLoading, error, refetch } = useQuery("services", () =>
    getProducts()
  );

  const services = data?.data || [];

  return { services, isLoading, error, refetch };
};

export default useServices;
