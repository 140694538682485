import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid } from "@mui/material";
import { TimePicker, renderTimeViewClock } from "@mui/x-date-pickers";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import FormDialog from "../FormDialog/FormDialog";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const schema = yup.object().shape({
  times: yup.array().of(
    yup.object().shape({
      initial: yup
        .date()
        .typeError("Campo obrigatório")
        .test(
          "start-before-end",
          "A hora de início deve ser menor que a hora de término",
          function (initial) {
            const final = dayjs(this.parent.final);
            if (dayjs(initial).isValid() && final.isValid()) {
              return dayjs(initial).isBefore(final);
            }
            return true;
          }
        ),
      final: yup
        .date()
        .typeError("Campo obrigatório")
        .test(
          "start-before-end",
          "A hora de término deve ser maior que a hora de início",
          function (final) {
            const initial = dayjs(this.parent.initial);
            if (initial.isValid() && dayjs(final).isValid()) {
              return dayjs(final).isAfter(initial);
            }
            return true;
          }
        ),
    })
  ),
});

const TimesDialog = ({ handleClose, selectedDay, handleChangeDayTimes }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      times:
        selectedDay.times.map((time) => ({
          initial: dayjs(time.initial, "HH:mm"),
          final: dayjs(time.final, "HH:mm"),
        })) || [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "times",
  });

  const onSubmit = (data) => {
    const times = data.times.map((time) => ({
      initial: dayjs(time.initial).format("HH:mm"),
      final: dayjs(time.final).format("HH:mm"),
    }));

    handleChangeDayTimes(selectedDay.day, times);
    handleClose();
  };

  return (
    <FormDialog
      open={true}
      title={"Horários - " + selectedDay.title}
      handleClose={handleClose}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        {fields.map((_, index) => (
          <Grid container spacing={3} marginBottom={3}>
            <Grid item xs={6}>
              <Controller
                name={`times[${index}].initial`}
                control={control}
                render={({ field }) => (
                  <TimePicker
                    label="Horário de abertura"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    ampm={false}
                    clearable
                    minutesStep={5}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!errors.times?.[index]?.initial,
                        helperText:
                          errors.times?.[index]?.initial?.message || "",
                      },
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name={`times[${index}].final`}
                control={control}
                render={({ field }) => (
                  <TimePicker
                    label="Horário de fechamento"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    ampm={false}
                    minutesStep={5}
                    clearable
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!errors.times?.[index]?.final,
                        helperText: errors.times?.[index]?.final?.message || "",
                      },
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        ))}

        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{
            height: "56px",
          }}
        >
          CONFIRMAR
        </Button>
      </Box>
    </FormDialog>
  );
};

export default TimesDialog;
