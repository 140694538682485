import { CssBaseline } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppRouter } from "./Routes/AppRouter";
import ThemeTemplate from "./components/templates/ThemeTemplate/ThemeTemplate";
import AppContextProvider from "./providers/AppContext";
import "./styles/global.css";
import "dayjs/locale/pt-br";
import PageTags from "./components/organisms/PageTags";

function App() {
  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CssBaseline />
        <ThemeTemplate>
          <AppContextProvider>
            <PageTags />
            <AppRouter />
          </AppContextProvider>
        </ThemeTemplate>

        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="light"
        />
      </LocalizationProvider>
    </div>
  );
}

export default App;
