import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import Breadcrumbs from "../../components/organisms/Breadcrumbs";
import useUserApp from "../../hooks/queries/useUserApp";
import { updateAppPhones } from "../../services/apps";
import { formatPhoneNumber } from "../../utils/string";
import { PageTitle } from "../../components/molecules/PageTitle";

const schema = yup
  .object({
    phone: yup.string().required("Digite o telefone"),
    phoneIsWpp: yup.string(),
  })
  .required();

const UpdatePhones = () => {
  let navigate = useNavigate();

  const { app: userApp, refetch } = useUserApp();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const values = userApp?.phones?.length > 0 ? userApp.phones[0] : null;

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      phone: values?.phone ? formatPhoneNumber(values.phone) : "",
      phoneIsWpp: values?.isWhatsapp || "",
    },
  });

  const onSubmit = (data) => {
    setIsSubmitting(true);

    const phones = [
      {
        phone: data.phone
          .replace("(", "")
          .replace(")", "")
          .replaceAll(" ", "")
          .replace("-", ""),
        isWhatsapp: data.phoneIsWpp,
      },
    ];

    updateAppPhones(userApp._id, phones)
      .then(() => {
        toast.success("Atualizado com sucesso!");
        refetch();
        navigate("/settings");
      })
      .catch(() => {
        toast.error("Erro ao atualizar!");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
     <PageTitle
        title={`Alterar telefone`}
        breadcrumbs={[
          {
            label: "Configurações",
            path: "/settings",
          },
          {
            label: "Telefone",
          },
        ]}
      />

      <Box
        component="form"
        sx={{ textAlign: "center" }}
        onSubmit={handleSubmit(onSubmit)}
        maxWidth="sm"
      >
        <Grid container spacing={2} marginTop={2}>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              label="Telefone"
              variant="outlined"
              fullWidth
              {...register("phone")}
              error={!!errors.phone}
              helperText={errors.phone?.message}
              onChange={(e) => {
                e.target.value = formatPhoneNumber(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <Controller
                name="phoneIsWpp"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} color="primary" />}
                    label="Esse número é WhatsApp?"
                  />
                )}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                height: "56px",
              }}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Salvando..." : <>Salvar</>}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default UpdatePhones;
