import api from "../config/api";
import apiUser from "../config/api-user";
import { AUTH_HEADER } from "../utils/header";

export const getUserById = (id) => {
  return api.get(`/users/${id}`, {});
};

export const getByUsername = (username) => {
  return api.get(`/users/getByUsername/${username}`, {});
};

export const updateUsername = (username) => {
  return api.patch(
    `/users/auth/updateUsername`,
    { username },
    {
      headers: AUTH_HEADER,
    }
  );
};

export const updateEmail = (email) => {
  return api.patch(
    `/users/auth/updateEmail`,
    { email },
    {
      headers: AUTH_HEADER,
    }
  );
};

export const updatePassword = (data) => {
  return api.patch(`/users/auth/updatePassword`, data, {
    headers: AUTH_HEADER,
  });
};


export const getCurrentUser = () => {
  return apiUser.get(`/current-user`);
};
