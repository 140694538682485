import { useCallback } from "react";
import { useQuery } from "react-query";
import { getCategories } from "../services/categories";

const useCategories = () => {
  const { data, isLoading, error, refetch } = useQuery("categories", () =>
    getCategories()
  );

  const categories = data?.data || [];

  const showCategoryName = useCallback(
    (id) => {
      const category = categories.find((category) => category._id === id);
      return category?.name;
    },
    [categories]
  );

  return { categories, isLoading, error, refetch, showCategoryName };
};

export default useCategories;
