import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Box, Button, Divider } from "@mui/material";
import { NewAppContext } from "./NewApp";
import { CATEGORIES } from "../../constants/category";

const Category = () => {
  const { values, handleValues } = React.useContext(NewAppContext);

  const [selectedList, setSelectedList] = React.useState(
    values.categories || []
  );

  const findItem = (key) => selectedList.find((item) => item === key);
  const isSeleted = (key) => !!findItem(key);

  const handleSelect = (key) => {
    const founded = selectedList.find((item) => item === key);

    if (founded) {
      setSelectedList((current) => current.filter((item) => item !== key));
    } else {
      setSelectedList((current) => [...current, key]);
    }
  };

  const onSubmit = () => {
    handleValues({
      categories: selectedList,
    });
  };

  return (
    <>
      <FormGroup>
        {CATEGORIES.map((item) => (
          <Box key={item.key}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isSeleted(item.key)}
                  onChange={() => handleSelect(item.key)}
                />
              }
              label={item.name}
              sx={{
                my: 1,
              }}
            />
            <Divider />
          </Box>
        ))}
      </FormGroup>

      <Button
        type="submit"
        variant="contained"
        fullWidth
        sx={{
          height: "56px",
        }}
        disabled={selectedList.length === 0}
        onClick={onSubmit}
      >
        CONTINUAR
      </Button>
    </>
  );
};

export default Category;
