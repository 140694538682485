import AppTemplate from "../components/templates/AppTemplate";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Avatar, Box, Typography } from "@mui/material";

require("moment/locale/pt-br");

const localizer = momentLocalizer(moment);

const lang = {
  "pt-br": {
    week: "Semana",
    work_week: "Semana de trabalho",
    day: "Dia",
    month: "Mês",
    previous: "Anterior",
    next: "Próximo",
    today: "Hoje",
    agenda: "Agenda",
    showMore: (total) => `+ ${total} mais`,
  },
};

const myEventsList = [
  {
    title: "My event",
    start: new Date(),
    end: new Date(moment().add(1, "hours")),
    person_id: 1,
  },
  {
    title: "My event2",
    start: new Date(),
    end: new Date(moment().add(2, "hours")),
    person_id: 2,
  },
];

const persons = [
  {
    id: 0,
    name: "Todos",
    image: null,
  },
  {
    id: 1,
    name: "John Doe",
    image: "https://material-ui.com/static/images/avatar/1.jpg",
  },
  {
    id: 2,
    name: "Jane Doe",
    image: "https://material-ui.com/static/images/avatar/2.jpg",
  },
];

const Schedule = () => {
  return (
    <AppTemplate>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 2,
        }}
      >
        {persons.map((person) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              mx: 1,
              cursor: "pointer",
            }}
          >
            <Avatar alt={person.name} src={person.image} />
            <Typography variant="body2" color="text.secondary" component="p">
              {person.name}
            </Typography>
          </Box>
        ))}
      </Box>

      <Calendar
        localizer={localizer}
        events={myEventsList}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        messages={lang["pt-br"]}
      />
    </AppTemplate>
  );
};

export default Schedule;
