import LockIcon from '@mui/icons-material/Lock';
import { Box, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import AppTemplate from "../components/templates/AppTemplate";

const AccessDanied = () => {
  return (
    <AppTemplate>
      <Box sx={{ textAlign: "center" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LockIcon sx={{ fontSize: 100 }} />
        </Box>{" "}
        <h2>Acesso negado</h2>

        <Link
            to="/"
            sx={{ fontWeight: 500 }}
            component={RouterLink}
          >
            Voltar ao início
          </Link>
      </Box>
    </AppTemplate>
  );
};

export default AccessDanied;
