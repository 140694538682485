import apiApplogged from "../config/api-app-logged";
import { STORES_PATH } from "../constants/store";

export const getProducts = () => {
  return apiApplogged.get(`${STORES_PATH}/products`);
};

export const getProduct = (productId) => {
  return apiApplogged.get(`${STORES_PATH}/products/${productId}`);
};

export const createProduct = (product) => {
  return apiApplogged.post(`${STORES_PATH}/products`, product);
};

export const updateProduct = (productId, product) => {
  return apiApplogged.put(`${STORES_PATH}/products/${productId}`, product);
};

export const deleteProduct = (productId) => {
  return apiApplogged.delete(`${STORES_PATH}/products/${productId}`);
};
