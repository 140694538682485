import React from "react";
import PropTypes from "prop-types";
import { Box, Card, CardContent, Container, Typography } from "@mui/material";
import { green, lightGreen } from "@mui/material/colors";
import { DEFAULT_COLOR } from "../../../config/color";
import useApp from "../../../hooks/queries/useApp";

const AuthTemplate = ({ children }) => {
  const { app } = useApp();

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        flexDirection: {
          xs: "column",
          md: "row",
        },
      }}
    >
      <Box
        sx={{
          width: {
            xs: "100%",
          },
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Container maxWidth="xs">
          <Card>
            <CardContent>
              <Box
                sx={{
                  mb: 4,
                }}
              >
                <Typography variant="h4" sx={{ fontSize: 54, mb: 1 }} color="primary">
                  {app.name}
                </Typography>
                <Typography
                  variant="p"
                  sx={{ fontSize: 24, fontWeight: 300 }}
                  color="primary"
                >
                  {app.description}
                </Typography>
              </Box>
              <Box>{children}</Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </Box>
  );
};

AuthTemplate.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AuthTemplate;
